import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { FaFileInvoice, FaFileInvoiceDollar } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { GiMoneyStack } from "react-icons/gi";

import AddOrganization from "../Modals/addOrganization";
import AddPO from "../Modals/addPO";
import AddInv from "../Modals/addInv";
import AddPay from "../Modals/addPay";
import EdithPO from "../Modals/edithPO";
import ListPO from "../Modals/listPO";
import SelectPO from "../Modals/selectPO";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const dispatch = useDispatch();

  const unpaidInvoice = useSelector((state) => state.dashboard.unpaidInvoice);
  const organization = useSelector((state) => state.dashboard.organization);

  useEffect(() => {
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      const Count_Active_Org = gql`
        {
          Count_Active_Org {
            com_name
            status
            com_id
          }
        }
      `;
      const Count_Active_INV = gql`
        {
          Count_Active_INV {
            code_po
            balance
            rec_id
          }
        }
      `;

      const AllOrg = await graphQLClient.request(Count_Active_Org);
      const AllINV = await graphQLClient.request(Count_Active_INV);
      let CountOrg = AllOrg["Count_Active_Org"].length;
      let CountINV = AllINV["Count_Active_INV"].length;

      dispatch(
        reducer.getAllCount({ unpaidInvoice: CountINV, organization: CountOrg })
      );
    }
    main().catch((error) => {
      console.error(error);
    });
  }, [dispatch]);

  const [dashData, setDashData] = useState({
    poorg: "",
    errpoorg: "",
    disabled: false,
    status: false,
    tryme: "",
  });

  function handlePOOrg(event) {
    const { name, value } = event.target;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*=><"'])/;
    if (name === "poorg") {
      let errMsg = dashData.errpoorg;
      const orgInputValue = event.target.value.trim().toLowerCase();
      const minLengthRegExp = /.{3,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "This is empty";
      } else if (orgLength > 255) {
        errMsg = "maximum 255 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 3 characters";
      } else {
        errMsg = "";
      }
      setDashData((prevDashData) => ({
        ...prevDashData,
        errpoorg: errMsg,
        status: false,
        disabled: false,
        tryme: "",
        [name]: value.toLowerCase(),
      }));
    }
  }

  function handlePO(event) {
    if (!dashData.poorg) {
      toast.error("Fix warning & Resubmit");
      // let status = false;
      // let disabled = false;
      setDashData((prevDashData) => ({
        ...prevDashData,
        errpoorg: "This is empty",
        // status: status,
        // disabled: disabled,
      }));
    } else if (dashData.errpoorg) {
      toast.error("Fix warning & Resubmit");
      // let status = true;
      // let disabled = false;
      // setDashData((prevDashData) => ({
      //   ...prevDashData,
      //   status: status,
      //   disabled: disabled,
      // }));
    } else {
      let statusSend = "individual";
      let actTF = "";
      let tryme = "";
      async function main() {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });

        const variables = {
          Status: statusSend,
          period_S: dashData.poorg,
          period_E: "",
        };
        const Query_Org = gql`
          query ($Status: String!, $period_S: String!, $period_E: String!) {
            List_PO(Status: $Status, period_S: $period_S, period_E: $period_E) {
              code_po
              code_inv
              amount
              tax
              balance
              rec_id
              com_name
              date_po
              date_invoice
            }
          }
        `;
        const AllUsers = await graphQLClient.request(Query_Org, variables);

        if (AllUsers["List_PO"][0] !== undefined) {
          if (!dashData.tryme) {
            document.getElementById("modalCK").click();
          }
          dispatch(reducer.getEdith({ edithPO: AllUsers["List_PO"][0] }));
          dispatch(reducer.getMyStae({ myState: "donit" }));
          actTF = "true";
          tryme = "#modal-edith_po";
        } else {
          actTF = "false";
          tryme = "";
          toast.error("There are no records to display");
        }

        setDashData((prevDashData) => ({
          ...prevDashData,
          status: actTF,
          disabled: actTF,
          tryme: tryme,
        }));
      }
      main().catch((error) => {
        console.error(error);
      });
    }
  }

  function handleORG(params) {
    if (!dashData.poorg) {
      toast.error("Fix warning & Resubmit");
      setDashData((prevDashData) => ({
        ...prevDashData,
        errpoorg: "This is empty",
      }));
    } else if (dashData.errpoorg) {
      toast.error("Fix warning & Resubmit");
    } else {
      let statusSend = "individualORG";
      let actTF = "";
      let tryme = "";
      async function main() {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          Status: statusSend,
          period_S: dashData.poorg,
        };
        const Query_Org = gql`
          query ($Status: String!, $period_S: String!) {
            List_Org(Status: $Status, period_S: $period_S) {
              com_id
              com_name
              status
            }
          }
        `;
        const AllUsers = await graphQLClient.request(Query_Org, variables);
        dispatch(reducer.getList({ poList: AllUsers["List_Org"] }));
        if (AllUsers["List_Org"] !== undefined) {
          if (AllUsers["List_Org"].length === 0) {
            actTF = "false";
            tryme = "";
            toast.error("There are no records to display");
            // document.getElementById("modalCK2").click();
          } else if (!dashData.tryme) {
            document.getElementById("modalCK2").click();
          }
          // dispatch(reducer.getList({ poList: AllUsers["List_Org"] }));
          // dispatch(reducer.getMyStae({ myState: "donit" }));
          actTF = "true";
          tryme = "#modal-select_po";
        } else {
          actTF = "false";
          tryme = "";
          toast.error("There are no records to display");
        }

        setDashData((prevDashData) => ({
          ...prevDashData,
          status: actTF,
          disabled: actTF,
          tryme: tryme,
        }));
      }
      main().catch((error) => {
        console.error(error);
      });
    }
  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>
                      {!unpaidInvoice ? (
                        <span
                          className="spinner-grow text-light "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        unpaidInvoice
                      )}
                    </h3>
                    <p>Unpaid Invoice</p>
                  </div>
                  <div className="icon">
                    <FaFileInvoice className="icon" />
                  </div>
                  <Link
                    to="/po/list_po/:Unpaid_Invoice"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-6">
                <div className="small-box bg-secondary">
                  <div className="inner">
                    <h3>
                      {!organization ? (
                        <span
                          className="spinner-grow text-light "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        organization
                      )}
                    </h3>
                    <p>Organizations</p>
                  </div>
                  <div className="icon">
                    <ImOffice className="icon" />
                  </div>
                  <Link
                    to="/organizations/list_organizations/:all"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-3 p-2">
                  <button
                    data-toggle="modal"
                    data-target="#modal-add_po"
                    type="button"
                    className="btn btn-primary btn-block p-3"
                  >
                    <FaFileInvoice fontSize={50} />
                    <div className="m-1">
                      <b style={{ fontSize: "1em" }}>Add PO</b>
                    </div>
                  </button>
                </div>

                <div className="col-sm-3 p-2">
                  <button
                    data-toggle="modal"
                    data-target="#modal-add_invoice"
                    type="button"
                    className="btn btn-warning btn-block p-3"
                  >
                    <FaFileInvoiceDollar fontSize={50} />
                    <div className="m-1">
                      <b style={{ fontSize: "1em" }}>Add Invoice</b>
                    </div>
                  </button>
                </div>

                <div className="col-sm-3 p-2">
                  <button
                    data-toggle="modal"
                    data-target="#modal-add_payment"
                    type="button"
                    className="btn btn-success btn-block p-3"
                  >
                    <GiMoneyStack fontSize={50} />
                    <div className="m-1">
                      <b style={{ fontSize: "1em" }}>Add Payment</b>
                    </div>
                  </button>
                </div>

                <div className="col-sm-3 p-2">
                  <button
                    data-toggle="modal"
                    data-target="#modal-add_organizations"
                    type="button"
                    className="btn btn-secondary btn-block p-3"
                  >
                    <ImOffice fontSize={50} />
                    <div className="m-1">
                      <b style={{ fontSize: "1em" }}>Add Organizations</b>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2">
              <div className="row form-group">
                <label className="col-sm-12 col-form-label">
                  Search by PO / Organization
                </label>
                <div className="input-group ">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by PO / Organization"
                    id="poorg"
                    name="poorg"
                    onChange={handlePOOrg}
                    value={dashData.poorg}
                    autoComplete="poorg"
                    minLength="3"
                    maxLength="100"
                    required={{
                      borderBottom: dashData.errpoorg ? true : false,
                    }}
                    style={{
                      borderBottom: dashData.errpoorg && "3px solid red",
                    }}
                  />

                  <div className="input-group-append ">
                    <button
                      id="modalCK"
                      type="button"
                      className="btn btn-primary btn-block "
                      onClick={handlePO}
                      // disabled={dashData.disabled}
                      data-toggle="modal"
                      data-target={dashData.tryme}
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <b className="pl-3 pr-3" style={{ fontSize: "1em" }}>
                        PO
                      </b>
                    </button>
                  </div>
                  <div className="input-group-append ">
                    <button
                      id="modalCK2"
                      type="button"
                      className="btn btn-secondary btn-block "
                      onClick={handleORG}
                      // disabled={dashData.disabled}
                      data-toggle="modal"
                      data-target={dashData.tryme}
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <b className="pl-2 pr-2" style={{ fontSize: "1em" }}>
                        Organization
                      </b>
                    </button>
                  </div>
                </div>
                <span
                  className="col-sm-6"
                  style={{
                    color: "rgb(243, 182, 51)",
                    margin: "0px 0px 0px 20px",
                  }}
                >
                  {dashData.errpoorg}
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* add_PO */}
      <div className="modal fade" id="modal-add_po">
        <div className="modal-dialog">
          <AddPO />
        </div>
      </div>

      {/* add_invoice */}
      <div className="modal fade" id="modal-add_invoice">
        <div className="modal-dialog">
          <AddInv />
        </div>
      </div>

      {/* add_payment */}
      <div className="modal fade" id="modal-add_payment">
        <div className="modal-dialog">
          <AddPay />
        </div>
      </div>

      {/* add_organizations */}
      <div className="modal fade" id="modal-add_organizations">
        <div className="modal-dialog">
          <AddOrganization />
        </div>
      </div>

      {/* edith_po */}
      <div className="modal fade" id="modal-edith_po">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <EdithPO />
        </div>
      </div>

      {/* select_po */}
      <div className="modal fade" id="modal-select_po">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <SelectPO />
        </div>
      </div>

      {/* list_po */}
      <div className="modal fade" id="modal-list_po">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <ListPO />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
