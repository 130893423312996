// var string = "hello world";
// console.log(string.slice(0,1));     //o/p:- h
// console.log(string.charAt(0));      //o/p:- h
// console.log(string.substring(0,1)); //o/p:- h
// console.log(string.substr(0,1));    //o/p:- h
// console.log(string[0]);             //o/p:- h

// text-transform: none;	/* No capitalization, the text renders as it is (default) */
// text-transform: capitalize;	/* Transforms the first character of each word to uppercase */
// text-transform: uppercase;	/* Transforms all characters to uppercase */
// text-transform: lowercase;	/* Transforms all characters to lowercase */
// text-transform: initial;	/* Sets this property to its default value */
// text-transform: inherit;	/* Inherits this property from its parent element */

export function extractFirstLetters(nameArr) {
  let firstName = nameArr[0].charAt(0).toUpperCase();
  // let lastName = nameArr[1].charAt(0).toUpperCase();

  let FL_Logo = firstName;

  return FL_Logo;
}

// export function fullName(nameArr) {

//     let firstName = nameArr[0];
//     let lastName = nameArr[1];

//     let fullName = firstName +" "+lastName;

//     return fullName;
// }
