import React, { useState } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

// import $ from "jquery";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function EdithDate() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);

  const allData = useSelector((state) => state.dashboard.edithDate);

  function handleDateClose(event) {
    setStartDate(null);
    dispatch(
      reducer.getDate({
        edithDate: [],
      })
    );
  }
  function handleDate(event) {
    event.preventDefault();
    // console.log(allData["hh"]);

    if (startDate) {
      const formatYmd = () => startDate.toISOString().slice(0, 10);
      const date = formatYmd(new Date());
      let slcyr = date.split("-")[0];
      const cutdate = new Date().toDateString();
      let yr = cutdate.split(" ")[3];
      // console.log(slcyr, yr);
      if (slcyr <= yr) {
        async function main() {
          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            recid: allData["id"],
            where: allData["xx"],
            name: date,
          };
          const query = gql`
            mutation ($recid: String!, $where: String!, $name: String!) {
              Update_Date(recid: $recid, where: $where, name: $name) {
                code_po
                code_inv
                amount
                tax
                balance
                rec_id
                com_name
                date_po
                date_invoice
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);
          if (data["Update_Date"].length > 0) {
            if (allData["xx"] === "date_invoice") {
              dispatch(reducer.getPayList({ payList: data["Update_Date"] }));
            }
            if (allData["xx"] === "date_invoice_po") {
              dispatch(reducer.getEdith({ edithPO: data["Update_Date"][0] }));
            }
            if (allData["xx"] === "date_po") {
              dispatch(reducer.getEdith({ edithPO: data["Update_Date"][0] }));
            }
            if (allData["xx"] === "date_po_pay") {
              dispatch(reducer.getEdith({ edithPO: data["Update_Date"][0] }));
            }
            if (allData["xx"] === "date_inv_pay") {
              dispatch(reducer.getEdith({ edithPO: data["Update_Date"][0] }));
            }
            MySwal.fire("Changed!", "", "success");
          } else {
            MySwal.fire(allData["hh"] + " is not changed", "", "info");
          }
        }
        main().catch((error) => {
          // console.error(error);
          let message = error.message.substring(0, 25);
          // console.log(message);
          if ("GraphQL Error (Code: 409)" === message) {
            // return allData["hh"] + " is already in the system.";
            // toast.error("Name is already in the system.");
            MySwal.fire(
              allData["hh"] + " is already in the system",
              "",
              "error"
            );
          }
        });
      } else {
        MySwal.fire(
          "The selected year can not be more than this year",
          "",
          "error"
        );
      }
    }
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Date</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            // data-toggle="modal"
            // data-target="#modal-add_date"
            onClick={handleDateClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleDate}>
            <div className="" style={{ height: "320px", width: "150px" }}>
              <div>
                <label htmlFor="org ">Select New Date </label>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  // startDate={startDate}
                  onChange={(date) => setStartDate(date)}
                  maxDate={new Date()}
                  inline
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse p-2">
              <button
                type="button"
                className="btn btn-secondary ml-2"
                data-dismiss="modal"
                onClick={handleDateClose}
              >
                Close
              </button>
              <button
                // disabled={orgData.disabled}
                className="btn btn-primary pl-5 pr-5 btn-lg "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EdithDate;
