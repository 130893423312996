import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import * as reducer from "../reducer";

function AddOrganization() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [orgData, sethOrgData] = useState({
    errorg: "",
    status: false,
    statusMSG: "",
    disabled: false,
  });

  const [dashData, sethDashData] = useState({
    org: "",
  });

  function handleOrgClose(event) {
    sethOrgData((prevOrgData) => ({
      ...prevOrgData,
      errorg: "",
      status: false,
      statusMSG: "",
      disabled: false,
    }));
    sethDashData((prevDashData) => ({
      ...prevDashData,
      org: "",
    }));
    // window.location.reload();
  }

  function handleOrgChange(event) {
    const { name, value } = event.target;
    if (name === "org") {
      let errMsg = orgData.errorg;
      const orgInputValue = event.target.value.trim();
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Organization's Name is empty";
      } else if (orgLength > 255) {
        errMsg = "maximum 255 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errorg: errMsg,
      }));
    }
    sethDashData((prevDashData) => ({
      ...prevDashData,
      [name]: value,
    }));
  }
  function handleOrg(event) {
    event.preventDefault();

    let err = 0;
    let errorg = orgData.errorg;

    if (errorg) {
      err += 1;
    } else {
      err += 0;
    }

    if (err > 0) {
      toast.error("Fix warning & Resubmit");

      let statusMSG = "Fix warning & Resubmit";
      let status = true;
      let disabled = false;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = " Submitting";
      let status = true;
      let disabled = true;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
      MySwal.fire({
        icon: "question",
        title: "Do you want to add " + dashData.org + " ?",
        showCancelButton: true,
        confirmButtonText: "Yes add",
      }).then((result) => {
        if (result.isConfirmed) {
          async function main() {
            const endpoint = env.SERVER_URL + "api/users";
            const graphQLClient = new GraphQLClient(endpoint, {
              credentials: "include",
              mode: "cors",
            });
            const variables = {
              // userid: nameid,
              orgname: dashData.org,
            };
            const query = gql`
              mutation ($orgname: String!) {
                Create_Org(orgname: $orgname) {
                  com_name
                  status
                  com_id
                }
              }
            `;
            const data = await graphQLClient.request(query, variables);
            if (data["Create_Org"].length > 0) {
              MySwal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 3000,
              });
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "",
                status: false,
                statusMSG: "",
                disabled: false,
              }));
              sethDashData((prevDashData) => ({
                ...prevDashData,
                org: "",
              }));

              dispatch(
                reducer.getOrgCount({ organization: data["Create_Org"].length })
              );
              MySwal.fire("Added!", "", "success");
            }
          }
          main().catch((error) => {
            // console.error(error);
            let message = error.message.substring(0, 25);
            // console.log(message);
            if ("GraphQL Error (Code: 409)" === message) {
              toast.error("Name is already in the system.");
              let statusMSG = "Fix warning & Resubmit";
              let status = true;
              let disabled = false;
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                statusMSG: statusMSG,
                status: status,
                disabled: disabled,
                errorg: "Name is already in the system.",
              }));
            }
          });
        }
      });
    }
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Add Organization</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleOrgClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        <form onSubmit={handleOrg}>
          <div className="">
            <div>
              <label htmlFor="org ">Organization's Name </label>
              <input
                className="form-control form-control-border border-width-2"
                type="text"
                id="org"
                name="org"
                onChange={handleOrgChange}
                value={dashData.org}
                autoComplete="org"
                minLength="8"
                maxLength="255"
                disabled={orgData.disabled}
                required={{
                  borderBottom: orgData.errorg ? true : false,
                }}
                style={{
                  borderBottom: orgData.errorg && "3px solid red",
                }}
              />
              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 0px 20px",
                }}
              >
                {orgData.errorg}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row-reverse p-2">
            <button
              disabled={orgData.disabled}
              className="btn btn-primary pl-5 pr-5 btn-lg "
            >
              {orgData.disabled && (
                <span
                  className="spinner-border text-light spinner-border-sm "
                  role="status"
                  aria-hidden="true"
                >
                  {/* <ImSpinner9 className="sr-only" /> */}
                </span>
              )}
              <span>{orgData.status ? orgData.statusMSG : " Submit"}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddOrganization;
