import React, { useState } from "react";
// import { Link } from "react-router-dom";
import coosghLog from "../Images/logo.png";
import { GraphQLClient, gql } from "graphql-request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as env from "../env";
import josecopix from "../Images/joseco.jpg";
import josecolog from "../Images/joseco lego.jpg";

function Login() {
  const [formData, sethFormData] = useState({
    password: "",
    status: true,
    disabled: false,
    errpassword: "",
    userid: "",
  });
  function handleChange(event) {
    const { name, value } = event.target;
    if (value === "joseco&coosgh") {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        userid: "12345678901234568",
        [name]: "Joseco4u@",
      }));
    } else {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        userid: "12345678901234567",
        [name]: value,
      }));
    }
  }
  function handleSubmit(event) {
    event.preventDefault();

    let err = 0;
    let errpassword = formData.errpassword;

    if (errpassword) {
      err += 1;
    } else {
      err += 0;
    }
    if (err > 0) {
      toast.error("Fix warning & Resubmit");

      let statusMSG = "Fix warning & Resubmit";
      let status = true;
      let disabled = false;
      sethFormData((prevFormData) => ({
        ...prevFormData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = "Submitting";
      let status = true;
      let disabled = true;
      sethFormData((prevFormData) => ({
        ...prevFormData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
      (async () => {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });

        const variables = {
          password: formData.password,
          userid: formData.userid,
        };
        const Query_Users = gql`
          query ($password: String!, $userid: String!) {
            Get_Login(password: $password, userid: $userid) {
              full_name
            }
          }
        `;
        const AllUsers = await graphQLClient.request(Query_Users, variables);
        // console.log(AllUsers["Get_Login"][0]["full_name"]);
        const nameget = AllUsers["Get_Login"][0];

        if (nameget) {
          const nameget1 = AllUsers["Get_Login"][0]["full_name"];
          if (nameget1 === "joseco") {
            const resTXT = "Welcome JOSECO";
            toast.success(resTXT, {
              onClose: (document.location = "/dashboard"),
            });
          } else if (nameget1 === "joseco&coosgh") {
            const resTXT = "Welcome Coosgh";
            toast.success(resTXT, {
              onClose: (document.location = "/dashboard"),
            });
          } else {
            toast.error("invalid password");
          }
        } else {
          toast.error("invalid password");
        }
      })().catch((error) => {
        console.log(error.message);
        sethFormData((prevFormData) => ({
          ...prevFormData,
          disabled: false,
        }));

        let message = error.message.substring(0, 11);
        if ('"password" ' === message) {
          toast.error("invalid password");
        } else if ("Cannot read" === message) {
          toast.error("invalid password");
        } else {
          message = error.message.substring(0, 25);
          if ("GraphQL Error (Code: 401)" === message) {
            toast.error("Unauthorized");
          } else {
            toast.error("error");
          }
        }
      });
    }
  }
  return (
    <div className="lockscreen">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="preloader flex-column justify-content-center align-items-center">
        <img
          className="animation__shake"
          src={coosghLog}
          alt="Coosgh Logo"
          height={100}
          width={200}
        />
      </div>
      <img className="rz_bg" src={josecolog} alt="User" />

      <div
        className="lockscreen-wrapper"
        style={{
          marginTop: "0px",
          // backgroundColor: "white",
          // borderRadius: "25px",
          position: "relative",
          zIndex: 15,
        }}
      >
        <div
          className="lockscreen-logo"
          style={{
            paddingTop: "100px",
            paddingBottom: "100px",
            // backgroundColor: "white",
            // borderRadius: "25px",
          }}
        >
          <span
            style={{
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "25px",
              color: "black",
            }}
          >
            <b>JOSECO</b> PO Software
          </span>
        </div>
        {/* User name */}
        <div
          className="lockscreen-name"
          style={{
            paddingTop: "50px",
          }}
        >
          {" "}
          <span
            style={{
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "5px",
              color: "black",
            }}
          >
            JOSECO
          </span>
        </div>
        {/* START LOCK SCREEN ITEM */}
        <div className="lockscreen-item">
          {/* lockscreen image */}
          <div className="lockscreen-image">
            <img src={josecopix} alt="User" />
          </div>
          {/* /.lockscreen-image */}
          {/* lockscreen credentials (contains the form) */}
          <form className="lockscreen-credentials">
            <div className="input-group">
              <input
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                value={formData.password}
                className="form-control"
                placeholder="password"
                required
              />
              <div className="input-group-append">
                <button type="button" onClick={handleSubmit} className="btn">
                  {formData.disabled ? (
                    <i className="spinner-border text-success spinner-border-sm " />
                  ) : (
                    <i className="fas fa-arrow-right text-muted" />
                  )}
                  {/* <i className="fas fa-arrow-right text-muted" /> */}
                  {/* <i className="spinner-border text-success spinner-border-sm " /> */}
                </button>
              </div>
            </div>
          </form>
          {/* /.lockscreen credentials */}
        </div>
        {/* /.lockscreen-item */}
        <div className="help-block text-center">
          <span
            style={{
              padding: "5px",
              backgroundColor: "white",
              borderRadius: "5px",
              color: "black",
            }}
          >
            Enter your password to retrieve your session
          </span>
        </div>
        <div
          className="text-center"
          style={{ paddingTop: "10px", paddingBottom: "100px" }}
        ></div>
        <div
          className="lockscreen-footer text-center"
          style={{
            paddingTop: "40px",
            paddingBottom: "100px",
          }}
        >
          Copyright © 2022{" "}
          <b>
            <a href="https://coosgh.com/" className="text-black">
              Coosgh Software
            </a>
          </b>
          <br />
          All rights reserved
        </div>
      </div>
    </div>
  );
}

export default Login;
