import React from "react";
import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";
import * as env from "./env pc";
import { CookiesProvider } from "react-cookie";
import store from "./store";
import { Provider } from "react-redux";

// document.cookie = cookieName +"=" + cookieValue + ";domain=.example.com;path=/;expires=" + myDate;
document.cookie =
  "csw-token=" +
  env.API_TOKEN +
  ";domain=.josecogroup.com; path=/; max-age=86400;SameSite=None;Secure";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CookiesProvider>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </CookiesProvider>
  </Provider>
);
// ReactDOM.render(
//   <Provider store={store}>
//     <CookiesProvider>
//       {/* <React.Fragement> */}
//       <App />
//       {/* </React.Fragement> */}
//     </CookiesProvider>
//   </Provider>,
//   document.getElementById("root")
// );
