import { createSlice } from "@reduxjs/toolkit";
// import * as actionNames from './actionTypes';

let lastId = 0;

export const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState: {
    id: lastId,
    unpaidInvoice: "",
    organization: "",
    edithPO: [],
    edithDate: [],
    poList: [],
    payList: [],
    myState: [],
  },
  reducers: {
    getAllCount: (state, action) => {
      state.id += 1;
      state.unpaidInvoice = action.payload.unpaidInvoice;
      state.organization = action.payload.organization;
    },
    getOrgCount: (state, action) => {
      state.id += 1;
      //   state.unpaidInvoice = action.payload.unpaidInvoice;
      state.organization = action.payload.organization;
    },
    getInvCount: (state, action) => {
      state.id += 1;
      state.unpaidInvoice = action.payload.unpaidInvoice;
      // state.organization = action.payload.organization;
    },
    getEdith: (state, action) => {
      state.id += 1;
      state.edithPO = action.payload.edithPO;
      // state.organization = action.payload.organization;
    },
    getDate: (state, action) => {
      state.id += 1;
      state.edithDate = action.payload.edithDate;
      // state.organization = action.payload.organization;
    },
    getPayList: (state, action) => {
      state.id += 1;
      state.payList = action.payload.payList;
      // state.organization = action.payload.organization;
    },
    getList: (state, action) => {
      state.id += 1;
      state.poList = action.payload.poList;
      // state.organization = action.payload.organization;
    },
    getMyStae: (state, action) => {
      state.id += 1;
      state.myState = action.payload.myState;
      // state.organization = action.payload.organization;
    },
  },
});

export const {
  getAllCount,
  getOrgCount,
  getInvCount,
  getEdith,
  getDate,
  getList,
  getPayList,
  getMyStae,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
