import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import Header from "../Components/Header";
import Menu from "../Components/Menu";
import Dashboard from "../Components/Dashboard";
import Orglist from "../Components/Orglist";
import PoAllList from "../Components/PoAllList";
import PayAllList from "../Components/PayAllList";

// import Usersboard from "../Components/UsersBoard";
// import CreateUser from "../Components/CreateUser";

import Footer from "../Components/Footer";

import coosghLog from "../Images/logo.png";

function Admin() {
  const [cookies] = useCookies();
  const location = useLocation();
  let { type } = useParams();
  var css_Info = "";
  (async () => {
    css_Info = jwt_decode(cookies["css-token"]);
  })().catch((error) => {
    console.log(error);
    document.location = "/";
  });
  // console.log(css_Info);
  if (css_Info["_st"] !== "active") {
    return css_Info["_st"];
  }

  const curBrwloc = location["pathname"];
  var curBrw = curBrwloc.split("/")[1];
  !curBrw && (curBrw = "dashboard");

  const tableorg = "/organizations/list_organizations/" + type;
  const tablepo = "/po/list_po/" + type;
  const tablepay = "/payment/list_payment/" + type;

  return (
    <div className="wrapper">
      {/* Preloader */}
      <div className="preloader flex-column justify-content-center align-items-center">
        <img
          className="animation__shake"
          src={coosghLog}
          alt="Coosgh Logo"
          height={100}
          width={200}
        />
      </div>

      <Header />
      <Menu />
      {curBrw === "dashboard" && <Dashboard />}
      {/* {curBrwloc === "/users/users_board" && <Usersboard />}
      {curBrwloc === "/users/create_user" && <CreateUser />} */}
      {curBrwloc === tableorg && <Orglist />}
      {curBrwloc === tablepo && <PoAllList />}
      {curBrwloc === tablepay && <PayAllList />}
      <Footer />
    </div>
  );
}

export default Admin;
