import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./reducer";
// import filledContactusSlice from './reducerContactus';

export default configureStore({
  reducer: {
    dashboard: dashboardSlice,
    // filledContactus: filledContactusSlice,
  },
});

// const store = createStore(
//     reducer,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     );

// export default store;
