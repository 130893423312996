import React, { useState } from "react";
import Select from "react-select";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import * as reducer from "../reducer";

function AddInv() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  const [orgData, sethOrgData] = useState({
    errpocode: "",
    status: false,
    statusMSG: "",
    disabled: false,
    errselect: "",
    errpodesc: "",
  });

  const [dashData, sethDashData] = useState({
    invcode: "",
    invdate: "",
    invamt: "",
    taxamt: "",
    selectorg: "",
    selectorgKey: "",
  });

  function handleOrgClose(event) {
    sethOrgData((prevOrgData) => ({
      ...prevOrgData,
      errpocode: "",
      status: false,
      statusMSG: "",
      disabled: false,
      errselect: "",
    }));
    sethDashData((prevDashData) => ({
      ...prevDashData,
      invcode: "",
      invdate: "",
      invamt: "",
      taxamt: "",
      selectorg: "",
      selectorgKey: "",
    }));
    // window.location.reload();
  }
  function handleClick(params) {
    // console.log("handleClick");
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      //   const variables = {
      //     userid: nameid,
      //     password: formData.passwordconfirm,
      //   };
      const Count_Active_PO = gql`
        {
          Count_Active_PO {
            code_po
            rec_id
          }
        }
      `;

      const AllOrg = await graphQLClient.request(Count_Active_PO);

      setOptions((prevSetOptions) => ({
        ...prevSetOptions,
        options: AllOrg["Count_Active_PO"].map((fbb, index) => {
          return {
            label: fbb.code_po,
            value: fbb.rec_id,
            key: index,
          };
        }),
      }));
    }
    main().catch((error) => {
      console.error(error);
    });
  }
  function handleSelect(params) {
    if (params) {
      sethDashData((prevDashData) => ({
        ...prevDashData,
        selectorg: params.value,
        selectorgKey: params.key,
      }));
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errselect: "",
        status: false,
        statusMSG: "",
        disabled: false,
      }));
      if (!params.value) {
        let statusMSG = "Fix warning & Resubmit";
        let status = true;
        let disabled = false;

        sethOrgData((prevOrgData) => ({
          ...prevOrgData,
          statusMSG: statusMSG,
          status: status,
          disabled: disabled,
          errselect: "Select a PO",
        }));
      }
    } else {
      sethDashData((prevDashData) => ({
        ...prevDashData,
        selectorg: "",
        selectorgKey: "",
      }));
    }
  }
  function handleOrgChange(event) {
    const { name, value } = event.target;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;

    if (name === "invcode") {
      let errMsg = orgData.errpocode;
      const orgInputValue = event.target.value.trim();
      const minLengthRegExp = /.{3,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Invoice Code is empty";
      } else if (orgLength > 100) {
        errMsg = "maximum 100 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 3 characters";
      } else {
        errMsg = "";
      }
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errpocode: errMsg,
      }));
    }
    if (name === "invdate") {
      let errMsg = orgData.errpodate;
      var date = new Date().toDateString();
      let yr = date.split(" ")[3];
      let poyr = value.split("-")[0];
      if (yr > poyr) {
        errMsg = "Invalid Date";
      } else {
        errMsg = "";
      }
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errpodate: errMsg,
      }));
    }
    if (name === "invamt") {
      let errMsg = orgData.errpodesc;
      const orgInputValue = event.target.value.trim();
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      if (orgLength > 100) {
        errMsg = "maximum 100 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (orgLength < 2) {
        errMsg = "At least minumum 2 digit";
      } else {
        errMsg = "";
      }
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errpodesc: errMsg,
      }));
      var taxamt = (3 / 100) * orgInputValue;
      taxamt = taxamt.toFixed(2);
      taxamt = taxamt.toString();
      sethDashData((prevDashData) => ({
        ...prevDashData,
        taxamt: taxamt,
      }));
    }

    sethDashData((prevDashData) => ({
      ...prevDashData,
      [name]: value,
    }));
  }
  function handleOrg(event) {
    event.preventDefault();

    let err = 0;
    if (!dashData.selectorg) {
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errselect: "Select an PO",
      }));
      err += 1;
    }
    let errpocode = orgData.errpocode;
    let errselect = orgData.errselect;
    let errpodate = orgData.errpodate;
    let errpodesc = orgData.errpodesc;

    if (errpocode) {
      err += 1;
    } else if (errselect) {
      err += 1;
    } else if (errpodate) {
      err += 1;
    } else if (errpodesc) {
      err += 1;
    } else {
      err += 0;
    }

    if (err > 0) {
      toast.error("Fix warning & Resubmit");

      let statusMSG = "Fix warning & Resubmit";
      let status = true;
      let disabled = false;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = " Submitting";
      let status = true;
      let disabled = true;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
      MySwal.fire({
        icon: "question",
        title:
          "Do you want to add " +
          dashData.invcode +
          " with an amount of " +
          dashData.invamt +
          " ?",
        showCancelButton: true,
        confirmButtonText: "Yes add",
      }).then((result) => {
        if (result.isConfirmed) {
          async function main() {
            const endpoint = env.SERVER_URL + "api/users";
            const graphQLClient = new GraphQLClient(endpoint, {
              credentials: "include",
              mode: "cors",
            });
            const variables = {
              selectpo: dashData.selectorg,
              invcode: dashData.invcode,
              invdate: dashData.invdate,
              invamt: dashData.invamt,
              taxamt: dashData.taxamt,
            };
            const query = gql`
              mutation (
                $selectpo: String!
                $invcode: String!
                $invdate: String!
                $invamt: String!
                $taxamt: String!
              ) {
                Create_INV(
                  selectpo: $selectpo
                  invcode: $invcode
                  invdate: $invdate
                  invamt: $invamt
                  taxamt: $taxamt
                ) {
                  rec_id
                  code_po
                }
              }
            `;
            const Count_Active_INV = gql`
              {
                Count_Active_INV {
                  code_po
                  balance
                  rec_id
                }
              }
            `;
            const data = await graphQLClient.request(query, variables);
            const AllINV = await graphQLClient.request(Count_Active_INV);

            if (data["Create_INV"].length > 0) {
              MySwal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 3000,
              });
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "",
                status: false,
                statusMSG: "",
                disabled: false,
              }));
              sethDashData((prevDashData) => ({
                ...prevDashData,
                selectorg: "",
                selectorgKey: "",
                invcode: "",
                invdate: "",
                invamt: "",
                taxamt: "",
              }));

              setOptions((prevSetOptions) => ({
                ...prevSetOptions,
                options: data["Create_INV"].map((fbb, index) => {
                  return {
                    label: fbb.code_po,
                    value: fbb.rec_id,
                    key: index,
                  };
                }),
              }));
              dispatch(
                reducer.getInvCount({
                  unpaidInvoice: AllINV["Count_Active_INV"].length,
                })
              );
              MySwal.fire("Added!", "", "success");
            } else {
              MySwal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 3000,
              });
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "",
                status: false,
                statusMSG: "",
                disabled: false,
              }));
              sethDashData((prevDashData) => ({
                ...prevDashData,
                selectorg: "",
                selectorgKey: "",
                invcode: "",
                invdate: "",
                invamt: "",
                taxamt: "",
              }));
              dispatch(
                reducer.getInvCount({
                  unpaidInvoice: AllINV["Count_Active_INV"].length,
                })
              );
            }
          }
          main().catch((error) => {
            // console.error(error);
            let message = error.message.substring(0, 25);
            let message2 = error.message.split('" ')[0];
            // console.log(message);
            if ("GraphQL Error (Code: 409)" === message) {
              toast.error("Code is already in the system.");
              let statusMSG = "Fix warning & Resubmit";
              let status = true;
              let disabled = false;
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "Code is already in the system.",
                statusMSG: statusMSG,
                status: status,
                disabled: disabled,
              }));
            }
            if ('"invcode' === message2) {
              toast.error("invalid Invoice Code");
              let statusMSG = "Fix warning & Resubmit";
              let status = true;
              let disabled = false;
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "invalid Invoice Code",
                statusMSG: statusMSG,
                status: status,
                disabled: disabled,
              }));
            }
            if ('"invdate' === message2) {
              toast.error("invalid Invoice Date");
              let statusMSG = "Fix warning & Resubmit";
              let status = true;
              let disabled = false;
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errpocode: "invalid Invoice Date",
                statusMSG: statusMSG,
                status: status,
                disabled: disabled,
              }));
            }
          });
        }
        if (result.isDismissed) {
          handleOrgClose();
        }
      });
    }
  }
  // useEffect(() => {
  //   async function main() {
  //     const endpoint = env.SERVER_URL + "api/users";
  //     const graphQLClient = new GraphQLClient(endpoint, {
  //       credentials: "include",
  //       mode: "cors",
  //     });
  //     //   const variables = {
  //     //     userid: nameid,
  //     //     password: formData.passwordconfirm,
  //     //   };
  //     const Count_Active_PO = gql`
  //       {
  //         Count_Active_PO {
  //           code_po
  //           rec_id
  //         }
  //       }
  //     `;

  //     const AllOrg = await graphQLClient.request(Count_Active_PO);

  //     setOptions((prevSetOptions) => ({
  //       ...prevSetOptions,
  //       options: AllOrg["Count_Active_PO"].map((fbb, index) => {
  //         return {
  //           label: fbb.code_po,
  //           value: fbb.rec_id,
  //           key: index,
  //         };
  //       }),
  //     }));
  //   }
  //   main().catch((error) => {
  //     console.error(error);
  //   });
  // }, []);
  // console.log("run inv");
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Add Invoice</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleOrgClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        <form onSubmit={handleOrg}>
          <div className="">
            <div>
              <label>Select PO </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={options["value: "]}
                // isDisabled
                // isLoading
                isClearable
                // isRtl
                isSearchable
                value={
                  dashData.selectorgKey !== "" &&
                  options.options[dashData.selectorgKey]
                }
                onFocus={handleClick}
                onChange={handleSelect}
                name="selectorg"
                id="selectorg"
                options={options.options}
              />

              <span
                style={{
                  color: "rgb(243, 182, 51)",
                  margin: "0px 0px 30px 30px",
                }}
              >
                {orgData.errselect}
              </span>
            </div>

            <div className="row">
              <div className="col-sm-7">
                <label htmlFor="org ">Invoice Code </label>
                <input
                  className="form-control form-control-border border-width-2"
                  type="text"
                  id="invcode"
                  name="invcode"
                  onChange={handleOrgChange}
                  value={dashData.invcode}
                  autoComplete="invcode"
                  minLength="3"
                  maxLength="100"
                  disabled={orgData.disabled}
                  required={{
                    borderBottom: orgData.errpocode ? true : false,
                  }}
                  style={{
                    borderBottom: orgData.errpocode && "3px solid red",
                  }}
                />
                <span
                  style={{
                    color: "rgb(243, 182, 51)",
                    margin: "0px 0px 0px 20px",
                  }}
                >
                  {orgData.errorg}
                </span>
              </div>
              <div className="col-sm-5">
                <label>Invoice Date</label>
                <input
                  className="form-control form-control-border border-width-2"
                  type="Date"
                  id="invdate"
                  name="invdate"
                  onChange={handleOrgChange}
                  value={dashData.invdate}
                  maxLength="10"
                  disabled={orgData.disabled}
                  required={{
                    borderBottom: orgData.errpodate ? true : false,
                  }}
                  style={{
                    borderBottom: orgData.errpodate && "3px solid red",
                  }}
                />
                <span
                  style={{
                    color: "rgb(243, 182, 51)",
                    margin: "0px 0px 30px 30px",
                  }}
                >
                  {orgData.errpodate}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <label>Invoice Amount</label>
                <input
                  className="form-control form-control-border border-width-2"
                  type="number"
                  id="invamt"
                  name="invamt"
                  onChange={handleOrgChange}
                  value={dashData.invamt}
                  autoComplete="invamt"
                  minLength="2"
                  maxLength="255"
                  disabled={orgData.disabled}
                  style={{
                    borderBottom: orgData.errpodesc && "3px solid red",
                  }}
                />
                <span
                  style={{
                    color: "rgb(243, 182, 51)",
                    margin: "0px 0px 30px 30px",
                  }}
                >
                  {orgData.errpodesc}
                </span>
              </div>
              <div className="col-sm-6">
                <label>Tax on the amount</label>
                <input
                  className="form-control "
                  type="number"
                  id="taxamt"
                  name="taxamt"
                  value={dashData.taxamt}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse p-2">
            <button
              disabled={orgData.disabled}
              className="btn btn-primary pl-5 pr-5 btn-lg "
            >
              {orgData.disabled && (
                <span
                  className="spinner-border text-light spinner-border-sm "
                  role="status"
                  aria-hidden="true"
                >
                  {/* <ImSpinner9 className="sr-only" /> */}
                </span>
              )}
              <span>{orgData.status ? orgData.statusMSG : " Submit"}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddInv;
