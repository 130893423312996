import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import SuperAdmin from "./Pages/SuperAdmin";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route
          path="/"
          element={LoggedIn === "super admin" ? <SuperAdmin /> : <Login />}
        /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<SuperAdmin />} />
        <Route
          exact
          path="/organizations/list_organizations/:type"
          element={<SuperAdmin />}
        />
        <Route exact path="/po/list_po/:type" element={<SuperAdmin />} />
        <Route
          exact
          path="/payment/list_payment/:type"
          element={<SuperAdmin />}
        />
      </Routes>
    </Router>
  );
}

export default App;
