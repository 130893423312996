import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FaFileInvoice } from "react-icons/fa";
// import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { ImOffice } from "react-icons/im";
import { GiMoneyStack } from "react-icons/gi";

// import coosghLog from "../Images/logo.png";

function Menu() {
  const location = useLocation();
  const curBrwloc = location["pathname"];
  var curBrw = curBrwloc.split("/")[1];
  var curBrw2 = curBrwloc.split("/")[2];
  !curBrw && (curBrw = "dashboard");

  const [formData, sethFormData] = useState({
    menuWhich: "",
  });

  function handleLogout() {
    document.cookie = "css-token=; Max-Age=0;secure;path=/;";
    document.location = "/";
  }

  function menuorg(params) {
    if (formData.menuWhich === "organizations") {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "",
      }));
    } else {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "organizations",
      }));
    }
  }
  function menupo(params) {
    if (formData.menuWhich === "po") {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "",
      }));
    } else {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "po",
      }));
    }
  }
  function menupayment(params) {
    if (formData.menuWhich === "payment") {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "",
      }));
    } else {
      sethFormData((prevFormData) => ({
        ...prevFormData,
        menuWhich: "payment",
      }));
    }
  }
  function menudashboard(params) {
    sethFormData((prevFormData) => ({
      ...prevFormData,
      menuWhich: "dashboard",
    }));
  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <h1 className="text-white-50">
        <b>JOSECO PO</b>
      </h1>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              onClick={menudashboard}
              className={
                formData.menuWhich === "dashboard"
                  ? "nav-item has-treeview menu-open"
                  : "nav-item has-treeview"
              }
            >
              <Link
                to="/dashboard"
                className={
                  curBrw === "dashboard" ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </Link>
            </li>

            <li
              onClick={menuorg}
              className={
                formData.menuWhich === "organizations"
                  ? "nav-item has-treeview menu-open"
                  : "nav-item has-treeview"
              }
            >
              <Link
                to=""
                className={
                  curBrw === "organizations" ? "nav-link active" : "nav-link"
                }
              >
                <ImOffice className="nav-icon " />
                <p>
                  Organizations
                  <i className="fas fa-angle-left right" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/organizations/list_organizations/:all"
                    className={
                      curBrw2 === "list_organizations"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>List Organization</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              onClick={menupo}
              className={
                formData.menuWhich === "po"
                  ? "nav-item has-treeview menu-open"
                  : "nav-item has-treeview"
              }
            >
              <Link
                to=""
                className={curBrw === "po" ? "nav-link active" : "nav-link"}
              >
                <FaFileInvoice className="nav-icon " />
                <p>
                  PO's
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/po/list_po/:all"
                    className={
                      curBrw2 === "list_po" ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>List PO</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              onClick={menupayment}
              className={
                formData.menuWhich === "payment"
                  ? "nav-item has-treeview menu-open"
                  : "nav-item has-treeview"
              }
            >
              <Link
                to=""
                className={
                  curBrw === "payment" ? "nav-link active" : "nav-link"
                }
              >
                <GiMoneyStack className="nav-icon " />
                <p>
                  Payments
                  <i className="right fas fa-angle-left" />
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/payment/list_payment/:all"
                    className={
                      curBrw2 === "list_payment"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>List Payment</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link" onClick={handleLogout} to="/">
                <i className="nav-icon fas fa-sign-out-alt" />
                <p>Sign Out</p>
              </Link>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}

export default Menu;
