import React, { useEffect, useState } from "react";

import * as numberFormat from "../Services/numberFormat";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

function ListPO() {
  const dispatch = useDispatch();
  const payData = useSelector((state) => state.dashboard.poList);
  const myState = useSelector((state) => state.dashboard.myState);

  const [dashData, setDashData] = useState({
    totalsum: 0,
  });
  useEffect(() => {
    if (payData.length > 0) {
      let totalsum = 0;
      payData.forEach((element) => {
        totalsum = totalsum + Number(element.balance);
        // console.log(totalsum);
        setDashData((prevDashData) => ({
          ...prevDashData,
          totalsum: totalsum,
        }));
      });
    }
  }, [payData]);

  function handleOrgClose(event) {
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      const variables = {
        Status: myState,
      };
      const Query_Org = gql`
        query ($Status: String!) {
          List_PO(Status: $Status) {
            code_po
            code_inv
            amount
            tax
            balance
            rec_id
            com_name
            date_po
            date_invoice
          }
        }
      `;
      const AllUsers = await graphQLClient.request(Query_Org, variables);
      dispatch(reducer.getList({ poList: AllUsers["List_PO"] }));
    }
    main().catch((error) => {
      console.error(error);
    });

    dispatch(
      reducer.getEdith({
        edithPO: [],
      })
    );
    dispatch(reducer.getPayList({ payList: [] }));

    // window.location.reload();
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">
            {payData.length > 0 && payData[0].com_name} <br />(
            {numberFormat.currency(dashData.totalsum)} )
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleOrgClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">PO</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Amount</th>
                  <th scope="col">3% Tax</th>
                  <th scope="col">Balance</th>
                </tr>
              </thead>
              <tbody>
                {payData.map((fbb, index) => (
                  <tr key={index}>
                    <th scope="row">{Number(index) + 1}</th>
                    <td>{fbb.code_po}</td>
                    <td>{fbb.code_inv}</td>
                    <td>{numberFormat.currency(fbb.amount)}</td>
                    <td>{numberFormat.currency(fbb.tax)}</td>
                    <td>{numberFormat.currency(fbb.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListPO;
