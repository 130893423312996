import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import * as env from "../env";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  height: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  // width: 'auto',
  width: "100%",
  height: "100%",
};

export function ImageUpload(props) {
  const [percentage, setPercentage] = useState(0);

  const [files, setFiles] = useState([]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/jpeg", "image/png"],
    onDropAccepted: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setPercentage(0);
    },
  });
  const fileAcp = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img alt={file.name} src={file.preview} style={img} />
      </div>
    </div>
  ));
  // useEffect(
  //   () => () => {
  //       // Make sure to revoke the Object URL to avoid memory leaks
  //       files.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   [files]
  // );
  // console.log(acceptedFiles[0]);

  function handlesendFile(event) {
    // var file =files[0].path;
    let percent = 0;
    let data = new FormData();
    data.append("files", acceptedFiles[0]);
    data.append("id", props.id);
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        percent = Math.floor((loaded * 100) / total);
        // console.log( `${loaded}kb of ${total}kb | ${percent}%` )
        if (percent <= 100) {
          setPercentage(percent);
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // withCredentials: true,
      withCredentials: false,
    };

    axios
      .post(env.SERVER_URL + "api/files/profile", data, config)
      .then((res) => {
        // console.log(res);
        toast.success(res.data);

        // console.log('File uploaded successfully.')
      })
      .catch((error) => {
        console.error("Upload Error:", error);
        // console.log(percent)
        setPercentage(0);
      });
  }

  return (
    <div className="container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop (jpeg / png) files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        <h4>Uploaded File</h4>
        <ul>{fileAcp}</ul>
        {thumbs}
      </aside>
      <p>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handlesendFile}
        >
          Save image
        </button>
      </p>
      <div className="progress">
        <div
          className="progress-bar bg-primary progress-bar-striped"
          role="progressbar"
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: percentage + "%" }}
        >
          {percentage === 0
            ? percentage + "% Complete"
            : percentage + "% Complete (success)"}
        </div>
      </div>
      <span>
        {percentage === 0
          ? percentage + "% (Not Saved)"
          : percentage + "% Complete ()"}
      </span>
    </div>
  );
}

// export function sendFile(file) {
//   const [percentage, setPercentage] = useState(0)
//   let percent = 0
//   let data = new FormData()
//   data.append('files', file)
//   const config = {
//     onUploadProgress: (progressEvent) => {
//     const {loaded, total} = progressEvent;
//     percent = Math.floor((loaded * 100) / total)
//     console.log( `${loaded}kb of ${total}kb | ${percent}%` )
//       if(percent <= 100) {
//         setPercentage(percent)
//       }
//     },
//     headers: {
//       // custom headers goes here
//     }
//   }

//   const postResponse = axios.post(env.SERVER_URL+"api/files/upload", data, config)
//     .then(res => {
//       if (percent > 0) {
//         setPercentage(percent)
//       }else{
//         setTimeout(() => {
//           setPercentage(0)
//         }, 1000);
//       }
//     })
//     .catch((error) => {
//       console.error('Upload Error:', error)
//     })
//     postResponse.then(() => {
//       console.log('File uploaded successfully.')
//     }
//   )
//   return (
//     <div className="progress">
//       <div className="progress-bar bg-primary progress-bar-striped" role="progressbar" aria-valuenow={percentage} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
//         <span className="sr-only">40% Complete (success)</span>
//       </div>
//     </div>
//   );

// }
