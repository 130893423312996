import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../Prints/PrintOrglist";

import { CSVLink } from "react-csv";
import { Link, useParams } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";
import DataTable from "react-data-table-component-footer";
import * as env from "../env";
import { MdArrowDownward, MdAddBox } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Fill } from "react-icons/ri";
const sortIcon = <MdArrowDownward />;
const customStyles = {
  rows: {
    style: {
      fontSize: "17px",
      minHeight: "40px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "500",
      textTransform: "uppercase",
      paddingLeft: "8px",
      color: "rgb(34, 5, 90)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  pagination: {
    style: {
      fontSize: "15px",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => row.status === "active",
    style: {
      // backgroundColor: "rgba(63, 195, 128, 0.9)",
      // color: "rgba(63, 195, 128, 0.9)",
      "&:hover": {
        // cursor: "pointer",
        backgroundColor: "rgba(156, 241, 87, 0.164)",
        // color: "rgb(34, 5, 90)",
        // borderRadius: "25px",
      },
    },
  },
];

const CustomLoader = () => (
  <div
    className="spinner-grow text-primary"
    role="status"
    style={{ padding: "24px" }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="input-group mb-3 ">
      <input
        id="search"
        type="text"
        placeholder="Filter By Organization's Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="form-control input-lg"
      />
      <span className="input-group-append">
        <button
          id="rz_submitBtn"
          type="button"
          onClick={onClear}
          className="btn btn-light"
        >
          Clear Text
        </button>
      </span>
    </div>
  </>
);

function Orglist() {
  const componentRef = useRef(null);
  const MySwal = withReactContent(Swal);
  let { type } = useParams();
  const [usersData, sethUsersData] = useState({
    title: "",
    allUsers: [],
    org: "",
    statusSend: "",
    // pendingUsers: "",
    // deniedUsers: "",
  });

  const [orgData, sethOrgData] = useState({
    errorg: "",
    status: false,
    statusMSG: "",
    disabled: false,
  });

  function handleOrgClose(event) {
    sethOrgData((prevOrgData) => ({
      ...prevOrgData,
      errorg: "",
      status: false,
      statusMSG: "",
      disabled: false,
    }));
    sethUsersData((prevUsersData) => ({
      ...prevUsersData,
      org: "",
    }));
    // window.location.reload();
  }
  function handleOrgChange(event) {
    const { name, value } = event.target;
    if (name === "org") {
      let errMsg = orgData.errorg;
      const orgInputValue = event.target.value.trim();
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const orgLength = orgInputValue.length;
      const specialCharorg = specialCharRegExp.test(orgInputValue);
      const minLengthorg = minLengthRegExp.test(orgInputValue);

      if (orgLength === 0) {
        errMsg = "Organization's Name is empty";
      } else if (orgLength > 255) {
        errMsg = "maximum 255 characters";
      } else if (specialCharorg) {
        errMsg = "Special Characters is not allowed";
      } else if (!minLengthorg) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        errorg: errMsg,
      }));
    }
    sethUsersData((prevUsersData) => ({
      ...prevUsersData,
      [name]: value,
    }));
  }
  function handleOrg(event) {
    event.preventDefault();

    let err = 0;
    let errorg = orgData.errorg;

    if (errorg) {
      err += 1;
    } else {
      err += 0;
    }

    if (err > 0) {
      toast.error("Fix warning & Resubmit");

      let statusMSG = "Fix warning & Resubmit";
      let status = true;
      let disabled = false;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = " Submitting";
      let status = true;
      let disabled = true;
      sethOrgData((prevOrgData) => ({
        ...prevOrgData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
      MySwal.fire({
        icon: "question",
        title: "Do you want to add " + usersData.org + " ?",
        showCancelButton: true,
        confirmButtonText: "Yes add",
      }).then((result) => {
        if (result.isConfirmed) {
          async function main() {
            const endpoint = env.SERVER_URL + "api/users";
            const graphQLClient = new GraphQLClient(endpoint, {
              credentials: "include",
              mode: "cors",
            });
            const variables = {
              // userid: nameid,
              orgname: usersData.org,
            };
            const query = gql`
              mutation ($orgname: String!) {
                Create_Org(orgname: $orgname) {
                  com_name
                  status
                  com_id
                }
              }
            `;
            const data = await graphQLClient.request(query, variables);
            if (data["Create_Org"].length > 0) {
              MySwal.fire({
                icon: "success",
                title: "Successfully saved",
                showConfirmButton: false,
                timer: 3000,
              });
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                errorg: "",
                status: false,
                statusMSG: "",
                disabled: false,
              }));
              sethUsersData((prevUsersData) => ({
                ...prevUsersData,
                org: "",
                allUsers: data["Create_Org"],
              }));
              MySwal.fire("Added!", "", "success");
            }
          }
          main().catch((error) => {
            // console.error(error);
            let message = error.message.substring(0, 25);
            // console.log(message);
            if ("GraphQL Error (Code: 409)" === message) {
              toast.error("Name is already in the system.");
              let statusMSG = "Fix warning & Resubmit";
              let status = true;
              let disabled = false;
              sethOrgData((prevOrgData) => ({
                ...prevOrgData,
                statusMSG: statusMSG,
                status: status,
                disabled: disabled,
              }));
            }
          });
        }
      });
    }
  }

  useEffect(() => {
    var statusSend = "active";
    if (type === ":all") {
      sethUsersData((prevUsersData) => ({
        ...prevUsersData,
        title: "all organizations in the system",
        statusSend: "active",
      }));
      statusSend = "active";
    }

    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });

      const variables = {
        Status: statusSend,
      };
      const Query_Org = gql`
        query ($Status: String!) {
          List_Org(Status: $Status) {
            com_name
            status
            com_id
          }
        }
      `;
      const AllUsers = await graphQLClient.request(Query_Org, variables);
      sethUsersData((prevUsersData) => ({
        ...prevUsersData,
        allUsers: AllUsers["List_Org"],
      }));
    }
    const timeout = setTimeout(() => {
      main().catch((error) => {
        console.error(error);
      });
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [type]);
  const [pending, setPending] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const [rows, setRows] = React.useState([]);
  const filteredItems = usersData.allUsers.filter(
    (item) =>
      item.com_name &&
      item.com_name.toLowerCase().includes(filterText.toLowerCase())
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const EditComponent = ({ row }) => {
    const EditHandler = () => {
      MySwal.fire({
        title: "Enter new name",
        input: "text",
        inputLabel: "Organization's Name",
        inputPlaceholder: row.com_name,
        showCancelButton: true,
        confirmButtonText: "Change Name",
        inputValidator: (value) => {
          const orgInputValue = value.trim();
          const specialCharRegExp = /(?=.*?[#?!@$%^&*-<>=])/;
          const minLengthRegExp = /.{8,}/;
          const orgLength = orgInputValue.length;
          const specialCharorg = specialCharRegExp.test(orgInputValue);
          const minLengthorg = minLengthRegExp.test(orgInputValue);
          if (!value) {
            return "You need to write something!";
          } else if (!minLengthorg) {
            return "At least minumum 8 characters";
          } else if (specialCharorg) {
            return "Special Characters is not allowed";
          } else if (orgLength > 255) {
            return "maximum 255 characters";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(result.value);
          // console.log(row.com_id);
          async function main() {
            const endpoint = env.SERVER_URL + "api/users";
            const graphQLClient = new GraphQLClient(endpoint, {
              credentials: "include",
              mode: "cors",
            });
            const variables = {
              orgid: row.com_id,
              orgname: result.value,
            };
            const query = gql`
              mutation ($orgid: String!, $orgname: String!) {
                Update_Org(orgid: $orgid, orgname: $orgname) {
                  com_name
                  status
                  com_id
                }
              }
            `;
            const data = await graphQLClient.request(query, variables);
            if (data["Update_Org"].length > 0) {
              setToggleCleared(!toggleCleared);
              setFilterText("");
              setPending(true);
              sethUsersData((prevUsersData) => ({
                ...prevUsersData,
                org: "",
                allUsers: data["Update_Org"],
              }));
              setPending(false);
              MySwal.fire("Changed!", "", "success");
            } else {
              MySwal.fire("Row is not changed", "", "info");
            }
          }
          main().catch((error) => {
            // console.error(error);
            let message = error.message.substring(0, 25);
            // console.log(message);
            if ("GraphQL Error (Code: 409)" === message) {
              return "Name is already in the system.";
              // toast.error("Name is already in the system.");
            }
          });
        }
      });
    };

    return (
      <BiEdit
        style={{ fontSize: "25px", cursor: "pointer" }}
        onClick={EditHandler}
      />
    );
  };
  const DeleteComponent = ({ row }) => {
    const DeleteHandler = () => {
      MySwal.fire({
        icon: "question",
        title: "Do you want to delete " + row.com_name + " ?",
        showCancelButton: true,
        confirmButtonText: "Yes delete",
      }).then((result) => {
        if (result.isConfirmed) {
          main(row);
          async function main(row) {
            if (row !== undefined) {
              const getOrgid = row.com_id;
              const endpoint = env.SERVER_URL + "api/users";
              const graphQLClient = new GraphQLClient(endpoint, {
                credentials: "include",
                mode: "cors",
              });
              const variables = {
                Status: "deleted",
                orgid: getOrgid,
              };
              const query = gql`
                mutation ($orgid: String!, $Status: String!) {
                  Delete_Org(orgid: $orgid, Status: $Status) {
                    com_name
                    status
                    com_id
                  }
                }
              `;
              const data = await graphQLClient.request(query, variables);
              if (data["Delete_Org"].length > 0) {
                setToggleCleared(!toggleCleared);
                setFilterText("");
                setPending(true);
                sethUsersData((prevUsersData) => ({
                  ...prevUsersData,
                  org: "",
                  allUsers: data["Delete_Org"],
                }));
                setPending(false);
                MySwal.fire("Deleted!", "", "success");
              } else {
                MySwal.fire("Row is not deleted", "", "info");
              }
            }
          }
        }
      });
    };
    return (
      <RiDeleteBin5Fill
        style={{ fontSize: "25px", cursor: "pointer" }}
        onClick={DeleteHandler}
      />
    );
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      // console.log(selectedRows);
      try {
        selectedRows.forEach(DeleteOrg);
      } catch (error) {
        console.log(error);
      }
      async function DeleteOrg(Org) {
        if (Org !== undefined) {
          const getOrgid = Org.com_id;

          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            Status: "deleted",
            orgid: getOrgid,
          };
          const query = gql`
            mutation ($orgid: String!, $Status: String!) {
              Delete_Org(orgid: $orgid, Status: $Status) {
                com_name
                status
                com_id
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);
          if (data["Delete_Org"].length > 0) {
            MySwal.fire({
              icon: "success",
              title: "Successfully deleted",
              showConfirmButton: false,
              timer: 3000,
            });
            setToggleCleared(!toggleCleared);
            setFilterText("");
            setPending(true);
            sethUsersData((prevUsersData) => ({
              ...prevUsersData,
              org: "",
              allUsers: data["Delete_Org"],
            }));
            setPending(false);
          }
        }
      }
      DeleteOrg().catch((error) => {
        // toast.error(error);
        console.error(error);
      });

      // if (
      //   window.confirm(
      //     `Are you sure you want to delete:\r ${selectedRows.map(
      //       (r) => r.firstname + " " + r.lastname
      //     )}?`
      //   )
      // ) {
      //   setToggleCleared(!toggleCleared);
      //   // setData(differenceBy(data, selectedRows, "title"));
      // }
    };
    return (
      <button key="delete" onClick={handleDelete} className="btn btn-danger">
        Delete
      </button>
    );
  }, [MySwal, selectedRows, toggleCleared]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Organization's Name",
      selector: (row) => row.com_name,
      sortable: true,
      grow: 2,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    {
      cell: (row) => row.com_id.key !== null && <EditComponent row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "40px",
      style: {
        color: "blue",
      },
    },
    {
      cell: (row) => row.com_id.key !== null && <DeleteComponent row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "40px",
      style: {
        color: "red",
      },
    },
  ];
  // const yearSum = () => {
  //   let x = 0;
  //   data.map((item) => {
  //     x += item.year;
  //     return x;
  //   });
  //   return x;
  // };
  const footer = {
    // Role: yearSum(),
    com_name: "",
    // status: "",
    com_id: "",
  };
  const csvheaders = [
    { label: "Organization's Name", key: "com_name" },
    { label: "STATUS", key: "status" },
  ];

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeonClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Organizations Table</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">List Organization</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="d-flex p-2 justify-content-between">
              <h3 className=" ">Table for {usersData.title}</h3>
              <button
                data-toggle="modal"
                data-target="#modal-add_organizations"
                type="button"
                className="ml-5 btn"
              >
                <MdAddBox fontSize={30} color={"green"} />
              </button>
            </div>
            <div className="card-body">
              <DataTable
                title=" "
                customStyles={customStyles}
                pagination
                theme="solarized"
                columns={columns}
                data={filteredItems}
                // data={usersData.allUsers}
                conditionalRowStyles={conditionalRowStyles}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                contextActions={contextActions}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                // selectableRowsComponentProps={selectProps}

                // expandableRows
                // expandableRowsComponent={ExpandedComponent}
                highlightOnHover
                // pointerOnHover
                striped
                footer={footer}
                sortIcon={sortIcon}
                progressPending={pending}
                progressComponent={<CustomLoader />}
              />

              <CSVLink
                className="btn btn-success"
                data={filteredItems}
                headers={csvheaders}
                filename={"Organization List.csv"}
              >
                Download CSV
              </CSVLink>
              {/* <button
                data-toggle="modal"
                data-target="#modal-add_organizations"
                type="button"
                className="m-2 btn btn-primary"
              >
                Add Organization
              </button> */}
              <ReactToPrint
                documentTitle={"Table for " + usersData.title}
                trigger={() => (
                  <button className="m-2 btn btn-primary">Print Table</button>
                )}
                content={() => componentRef.current}
              />
              <div className="d-none d-print-block" ref={componentRef}>
                <ComponentToPrint
                  data={filteredItems.length !== 0 && filteredItems}
                />
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </section>
      </div>

      <div className="modal fade" id="modal-add_organizations">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Organization</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleOrgClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleOrg}>
                <div className="">
                  <div>
                    <label htmlFor="org ">Organization's Name </label>
                    <input
                      className="form-control form-control-border border-width-2"
                      type="text"
                      id="org"
                      name="org"
                      onChange={handleOrgChange}
                      value={usersData.org}
                      autoComplete="org"
                      minLength="8"
                      maxLength="255"
                      disabled={orgData.disabled}
                      required={{
                        borderBottom: orgData.errorg ? true : false,
                      }}
                      style={{
                        borderBottom: orgData.errorg && "3px solid red",
                      }}
                    />
                    <span
                      style={{
                        color: "rgb(243, 182, 51)",
                        margin: "0px 0px 30px 30px",
                      }}
                    >
                      {orgData.errorg}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <button
                    disabled={orgData.disabled}
                    className="btn btn-primary pl-5 pr-5 btn-lg "
                  >
                    {orgData.disabled && (
                      <span
                        className="spinner-border text-light spinner-border-sm "
                        role="status"
                        aria-hidden="true"
                      >
                        {/* <ImSpinner9 className="sr-only" /> */}
                      </span>
                    )}
                    <span>
                      {orgData.status ? orgData.statusMSG : " Submit"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orglist;
