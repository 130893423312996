import React from "react";
// import { useSelector } from "react-redux";

function PrintPOlist(data) {
  // const data = useSelector((state) => state.dashboard.poList);
  // console.log(data);
  if (data["data"].length > 0) {
    // console.log(data["data"]);
    const AmountSum = () => {
      let x = 0;
      data["data"].map((item) => {
        x += Number(item.amount);
        return x;
      });
      return x;
    };
    const TaxSum = () => {
      let x = 0;
      data["data"].map((item) => {
        x += Number(item.tax);
        return x;
      });
      return x;
    };

    const BalanceSum = () => {
      let x = 0;
      data["data"].map((item) => {
        x += Number(item.balance);
        return x;
      });
      return x;
    };
    return (
      <div>
        <h3 className="p-2 mt-5">PO list</h3>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Organization's Name</th>
              <th scope="col">PO</th>
              <th scope="col">PO Date</th>
              <th scope="col">Invoice</th>
              <th scope="col">Invoice Date</th>
              <th scope="col">Amount</th>
              <th scope="col">3% Tax</th>
              <th scope="col">Balance</th>
            </tr>
          </thead>
          <tbody>
            {data["data"].map((fbb, index) => (
              <tr key={index}>
                <th scope="row">{Number(index) + 1}</th>
                <td>{fbb.com_name}</td>
                <td>{fbb.code_po}</td>
                <td>{fbb.date_po}</td>
                <td>{fbb.code_inv}</td>
                <td>{fbb.date_invoice}</td>
                <td>{fbb.amount}</td>
                <td>{fbb.tax}</td>
                <td>{fbb.balance}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="d-flex flex-row-reverse p-2">Total Sum:</td>
              <td>{AmountSum().toLocaleString()}</td>
              <td>{TaxSum().toLocaleString()}</td>
              <td>{BalanceSum().toLocaleString()}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default PrintPOlist;
