import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as numberFormat from "../Services/numberFormat";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";
import { BiEdit } from "react-icons/bi";

function EdithPO() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const edithINFO = useSelector((state) => state.dashboard.edithPO);
  const myState = useSelector((state) => state.dashboard.myState);

  function handlePOCode(params) {
    MySwal.fire({
      title: "Enter New PO Code",
      input: "text",
      inputLabel: "PO Code",
      inputPlaceholder: edithINFO.code_po,
      showCancelButton: true,
      confirmButtonText: "Change PO Code",
      inputValidator: (value) => {
        const orgInputValue = value.trim();
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{3,}/;
        const orgLength = orgInputValue.length;
        const specialCharorg = specialCharRegExp.test(orgInputValue);
        const minLengthorg = minLengthRegExp.test(orgInputValue);
        if (!value) {
          return "You need to write something!";
        } else if (!minLengthorg) {
          return "At least minumum 8 characters";
        } else if (specialCharorg) {
          return "Special Characters is not allowed";
        } else if (orgLength > 100) {
          return "maximum 100 characters";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(result.value);
        // console.log(row.com_id);
        async function main() {
          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            recid: edithINFO.rec_id,
            where: "code_po_pay",
            name: result.value,
          };
          const query = gql`
            mutation ($recid: String!, $where: String!, $name: String!) {
              Update_Code(recid: $recid, where: $where, name: $name) {
                code_po
                code_inv
                amount
                tax
                balance
                rec_id
                com_name
                date_po
                date_invoice
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);

          if (data["Update_Code"].length > 0) {
            // sethPayData((prevPayData) => ({
            //   ...prevPayData,
            //   payData: data["Update_Code"],
            // }));
            dispatch(reducer.getEdith({ edithPO: data["Update_Code"][0] }));

            MySwal.fire("Changed!", "", "success");
          } else {
            MySwal.fire("PO Code is not changed", "", "info");
          }
        }
        main().catch((error) => {
          // console.error(error);
          let message = error.message.substring(0, 25);
          // console.log(message);
          if ("GraphQL Error (Code: 409)" === message) {
            // return "PO Code is already in the system.";
            // toast.error("Name is already in the system.");
            MySwal.fire("PO Code is already in the system.", "", "error");
          }
        });
      }
    });
  }

  function handlePOInv(params) {
    MySwal.fire({
      title: "Enter New Invoice Code",
      input: "text",
      inputLabel: "Invoice Code",
      inputPlaceholder: edithINFO.code_inv,
      showCancelButton: true,
      confirmButtonText: "Change Invoice Code",
      inputValidator: (value) => {
        const orgInputValue = value.trim();
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{3,}/;
        const orgLength = orgInputValue.length;
        const specialCharorg = specialCharRegExp.test(orgInputValue);
        const minLengthorg = minLengthRegExp.test(orgInputValue);
        if (!value) {
          return "You need to write something!";
        } else if (!minLengthorg) {
          return "At least minumum 8 characters";
        } else if (specialCharorg) {
          return "Special Characters is not allowed";
        } else if (orgLength > 100) {
          return "maximum 100 characters";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(result.value);
        // console.log(row.com_id);
        async function main() {
          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            recid: edithINFO.rec_id,
            where: "code_inv",
            name: result.value,
          };
          const query = gql`
            mutation ($recid: String!, $where: String!, $name: String!) {
              Update_Code(recid: $recid, where: $where, name: $name) {
                code_po
                code_inv
                amount
                tax
                balance
                rec_id
                com_name
                date_po
                date_invoice
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);

          if (data["Update_Code"].length > 0) {
            // sethPayData((prevPayData) => ({
            //   ...prevPayData,
            //   payData: data["Update_Code"],
            // }));
            dispatch(reducer.getEdith({ edithPO: data["Update_Code"][0] }));

            MySwal.fire("Changed!", "", "success");
          } else {
            MySwal.fire("Receipt Code is not changed", "", "info");
          }
        }
        main().catch((error) => {
          // console.error(error);
          let message = error.message.substring(0, 25);
          // console.log(message);
          if ("GraphQL Error (Code: 409)" === message) {
            // return "PO Code is already in the system.";
            // toast.error("Name is already in the system.");
            MySwal.fire("Receipt Code is already in the system.", "", "error");
          }
        });
      }
    });
  }

  function handlePOAmt(params) {
    MySwal.fire({
      title: "Enter New Amount",
      input: "text",
      inputLabel: "Amount",
      inputPlaceholder: edithINFO.amount,
      showCancelButton: true,
      confirmButtonText: "Change Invoice Amount",
      inputValidator: (value) => {
        const orgInputValue = value.trim();
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const numbersRegExp = orgInputValue.match(/^[0-9.]+$/);
        const minLengthRegExp = /.{2,}/;
        const orgLength = orgInputValue.length;
        const specialCharorg = specialCharRegExp.test(orgInputValue);
        const minLengthorg = minLengthRegExp.test(orgInputValue);
        if (!value) {
          return "You need to write something!";
        } else if (!minLengthorg) {
          return "At least minumum 2 characters";
        } else if (specialCharorg) {
          return "only numbers are allowed";
        } else if (!numbersRegExp) {
          return "only numbers are allowed";
        } else if (orgLength > 100) {
          return "maximum 100 characters";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(result.value);
        // console.log(row.com_id);
        async function main() {
          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            recid: edithINFO.rec_id,
            where: "amount",
            name: result.value,
          };
          const query = gql`
            mutation ($recid: String!, $where: String!, $name: String!) {
              Update_Amount(recid: $recid, where: $where, name: $name) {
                code_po
                code_inv
                amount
                tax
                balance
                rec_id
                com_name
                date_po
                date_invoice
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);

          if (data["Update_Amount"].length > 0) {
            // sethPayData((prevPayData) => ({
            //   ...prevPayData,
            //   payData: data["Update_Code"],
            // }));
            dispatch(reducer.getEdith({ edithPO: data["Update_Amount"][0] }));

            MySwal.fire("Changed!", "", "success");
          } else {
            MySwal.fire("Amount is not changed", "", "info");
          }
        }
        main().catch((error) => {
          // console.error(error);
          let message = error.message.substring(0, 25);
          // console.log(message);
          if ("GraphQL Error (Code: 409)" === message) {
            // return "PO Code is already in the system.";
            // toast.error("Name is already in the system.");
            MySwal.fire("Amount is already in the system.", "", "error");
          }
        });
      }
    });
  }

  function handlePODate(params) {
    dispatch(
      reducer.getDate({
        edithDate: {
          hh: params[0]["hh"],
          vv: params[1]["vv"],
          id: params[2]["id"],
          xx: params[3]["xx"],
        },
      })
    );
  }

  // const invAMTtax = Number(edithINFO.amount) + Number(edithINFO.tax);

  function handleOrgClose(event) {
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      const variables = {
        Status: myState[0],
        period_S: myState[1],
        period_E: myState[2],
      };
      const Query_Org = gql`
        query ($Status: String!, $period_S: String!, $period_E: String!) {
          List_PO(Status: $Status, period_S: $period_S, period_E: $period_E) {
            code_po
            code_inv
            amount
            tax
            balance
            rec_id
            com_name
            date_po
            date_invoice
          }
        }
      `;
      const AllUsers = await graphQLClient.request(Query_Org, variables);
      dispatch(reducer.getList({ poList: AllUsers["List_PO"] }));
    }
    main().catch((error) => {
      console.error(error);
    });

    dispatch(
      reducer.getEdith({
        edithPO: [],
      })
    );
    dispatch(reducer.getPayList({ payList: [] }));

    // window.location.reload();
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">
            {edithINFO.com_name} <br />(
            {numberFormat.currency(edithINFO.balance)} )
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleOrgClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="">
            <div className="row">
              <div className="col-sm-7">
                <label>PO Code </label>
                <label className="form-control form-control-border border-width-2">
                  {edithINFO.code_po}
                  <BiEdit
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: "green",
                    }}
                    className="pl-2"
                    onClick={handlePOCode}
                  />
                </label>
              </div>
              <div className="col-sm-5">
                <label>PO Date</label>
                <label className="form-control form-control-border border-width-2">
                  {edithINFO.date_po}
                  <BiEdit
                    style={{
                      fontSize: "30px",
                      cursor: "pointer",
                      color: "green",
                    }}
                    className="pl-2"
                    data-toggle="modal"
                    data-target="#modal-add_date"
                    data-backdrop="static"
                    data-keyboard="false"
                    onClick={() =>
                      handlePODate([
                        { hh: "PO Date" },
                        { vv: edithINFO.date_po },
                        { id: edithINFO.rec_id },
                        { xx: "date_po_pay" },
                      ])
                    }
                  />
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-7">
                <label htmlFor="org ">Receipt Code </label>
                <label className="form-control form-control-border border-width-2">
                  {edithINFO.code_inv}
                  {edithINFO.code_inv && (
                    <BiEdit
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "green",
                      }}
                      className="pl-2"
                      onClick={handlePOInv}
                    />
                  )}
                </label>
              </div>
              <div className="col-sm-5">
                <label>Receipt Date</label>
                <label className="form-control form-control-border border-width-2">
                  {edithINFO.date_invoice}
                  {edithINFO.date_invoice && (
                    <BiEdit
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "green",
                      }}
                      className="pl-2"
                      data-toggle="modal"
                      data-target="#modal-add_date"
                      data-backdrop="static"
                      data-keyboard="false"
                      onClick={() =>
                        handlePODate([
                          { hh: "Receipt Date" },
                          { vv: edithINFO.date_invoice },
                          { id: edithINFO.rec_id },
                          { xx: "date_inv_pay" },
                        ])
                      }
                    />
                  )}
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-7">
                <label>Receipt Amount </label>
                <label className="form-control form-control-border border-width-2">
                  {numberFormat.currency(edithINFO.amount)}
                  {edithINFO.amount && (
                    <BiEdit
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "green",
                      }}
                      className="pl-2"
                      onClick={handlePOAmt}
                    />
                  )}
                </label>
              </div>
              <div className="col-sm-5">
                <label>Tax</label>
                <label className="form-control form-control-border border-width-2">
                  {numberFormat.currency(edithINFO.tax)}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EdithPO;
