import React from "react";

function PrintOrglist(data) {
  // const data = useSelector((state) => state.dashboard.printList);
  if (data["data"].length > 0) {
    // console.log(data["data"]);
    return (
      <div>
        <h3 className="p-2 mt-5">Organization list</h3>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Organization's Name</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data["data"].map((fbb, index) => (
              <tr key={index}>
                <th scope="row">{Number(index) + 1}</th>
                <td>{fbb.com_name}</td>
                <td>{fbb.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PrintOrglist;
