import React, { useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import { Link } from "react-router-dom";

import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as env from "../env";
import * as namingStyle from "../Services/namingStyle";
import * as uploadFiles from "../Services/uploadFiles";

function Header() {
  const MySwal = withReactContent(Swal);

  const [pixData, sethPixData] = useState(0);
  const [formData, sethFormData] = useState({
    password: "",
    passwordconfirm: "",
  });
  const [passwordData, sethPasswordData] = useState({
    errpassword: "",
    errpasswordconfirm: "",
    status: false,
    statusMSG: "",
    disabled: false,
  });

  const [cookies] = useCookies();
  const css_Info = jwt_decode(cookies["css-token"]);
  // console.log(css_Info["_fn"]);

  const nameArr = [css_Info["_fn"]];
  const nameid = css_Info["_id"];
  const FL_Logo = namingStyle.extractFirstLetters(nameArr);
  const fullName = css_Info["_fn"];
  const profilePix = env.SERVER_URL + "uploads/pixs/" + css_Info["_px"];

  function pixClick() {
    sethPixData((prevPixData) => ({
      ...prevPixData,
      pixData: 1,
    }));
  }
  function handleLogout() {
    document.cookie = "css-token=; Max-Age=0;secure;path=/;";
    document.location = "/";
    // localStorage.clear('LoggedIn', "LoggedIn");
    // setTimeout(()=>{
    //     window.location.reload();
    // },1000);
  }
  function handleuserProfile() {
    window.location.reload();
  }
  function handlePassword(event) {
    event.preventDefault();

    let err = 0;
    let errpassword = passwordData.errpassword;
    let errpasswordconfirm = passwordData.errpasswordconfirm;

    if (errpassword) {
      err += 1;
    } else if (errpasswordconfirm) {
      err += 1;
    } else {
      err += 0;
    }

    if (err > 0) {
      toast.error("Fix warning & Resubmit");

      let statusMSG = "Fix warning & Resubmit";
      let status = true;
      let disabled = false;
      sethPasswordData((prevPasswordData) => ({
        ...prevPasswordData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));
    } else {
      let statusMSG = " Submitting";
      let status = true;
      let disabled = true;
      sethPasswordData((prevPasswordData) => ({
        ...prevPasswordData,
        statusMSG: statusMSG,
        status: status,
        disabled: disabled,
      }));

      async function main() {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });
        const variables = {
          userid: "12345678901234567",
          password: formData.passwordconfirm,
        };
        const query = gql`
          mutation ($userid: String!, $password: String!) {
            Update_Password(userid: $userid, password: $password) {
              user_id
            }
          }
        `;
        const data = await graphQLClient.request(query, variables);
        // console.log(JSON.stringify(data["Update_Password"]["userid"]));
        // const chkID = JSON.stringify(data["Update_Password"]["user_id"]);
        // const chkIDRm = chkID.replace(/"/g, "");
        const chkIDRm = data["Update_Password"][0]["user_id"];
        // console.log(chkIDRm);
        if (chkIDRm === "12345678901234567") {
          // toast.success("Successfully changed");
          MySwal.fire({
            icon: "success",
            title: "Successfully changed",
            showConfirmButton: false,
            timer: 3000,
          });
          sethPasswordData((prevPasswordData) => ({
            ...prevPasswordData,
            errpassword: "",
            errpasswordconfirm: "",
            status: false,
            statusMSG: "",
            disabled: false,
          }));
          sethFormData((prevFormData) => ({
            ...prevFormData,
            password: "",
            passwordconfirm: "",
          }));
        }
      }
      main().catch((error) => {
        toast.error(error);
        console.error(error);
      });
    }
  }
  function handlePasswordChange(event) {
    const { name, value } = event.target;
    if (name === "password") {
      let errMsg = passwordData.errpassword;
      const passwordInputValue = event.target.value.trim();
      // const passwordInputFieldName = event.target.name;
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);

      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (passwordLength > 15) {
        errMsg = "maximum 15 characters";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      sethPasswordData((prevPasswordData) => ({
        ...prevPasswordData,
        errpassword: errMsg,
      }));
      sethFormData((prevFormData) => ({
        ...prevFormData,
        passwordconfirm: "",
      }));
    }
    if (name === "passwordconfirm") {
      let errMsg = passwordData.errpasswordconfirm;
      if (formData.password === value) {
        errMsg = "";
      } else {
        errMsg = "Password do not match";
      }
      sethPasswordData((prevPasswordData) => ({
        ...prevPasswordData,
        errpasswordconfirm: errMsg,
      }));
    }
    sethFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeonClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="pushmenu"
              to=""
              role="button"
            >
              <i className="fas fa-bars" />
            </Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          {/* <li className="nav-item"> */}
          {/* <Link
              className="nav-link"
              data-widget="navbar-search"
              to=""
              role="button"
            >
              <i className="fas fa-search" />
            </Link>
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button
                      className="btn btn-navbar"
                      type="button"
                      data-widget="navbar-search"
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
          {/* </li> */}
          {/* user profile  */}
          <div className="dropdown show dropleft">
            <li className="nav-item dropdown">
              {/* <img style={{width: 40, height: 40}} src={userPic} alt="Logo" className="brand-image img-circle elevation-3" /> */}
              <span
                className="nav-link brand-image img-circle elevation-3"
                style={{ cursor: "pointer" }}
                data-toggle="dropdown"
              >
                {FL_Logo}
              </span>

              <div id="userProfile" className="dropdown-menu dropdown-menu-lg">
                <div>
                  <div>
                    <img
                      id="Pimage"
                      data-toggle="modal"
                      data-target="#modal-profilePix"
                      className="img-circle elevation-3  mx-auto d-block"
                      style={{ width: 220, height: 220, cursor: "pointer" }}
                      src={profilePix}
                      alt="User Avatar"
                      onClick={pixClick}
                    />
                  </div>
                  <h4 className="p-2" style={{ textTransform: "capitalize" }}>
                    {fullName}
                  </h4>
                  <div className="p-3">
                    <button
                      data-toggle="modal"
                      data-target="#modal-passwordChange"
                      className="p-2 btn btn-block btn-outline-info"
                      type="button"
                      value="Password/Password"
                      // onClick={handlePassword}
                    >
                      Password Change
                    </button>
                    <p>&nbsp;</p>
                    <Link
                      to=""
                      className="btn btn-block btn-outline-success"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                    {/* </a> */}
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </nav>

      <div className="modal fade" id="modal-profilePix">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Upload Image</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleuserProfile}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {pixData && <uploadFiles.ImageUpload id={nameid} />}
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={handleuserProfile}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modal-passwordChange">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Password Change</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handlePassword}>
                <div className="">
                  <div>
                    <label htmlFor="password ">Password </label>
                    <input
                      className="form-control form-control-border border-width-2"
                      type="password"
                      id="password"
                      name="password"
                      onChange={handlePasswordChange}
                      value={formData.password}
                      autoComplete="password"
                      minLength="8"
                      maxLength="16"
                      disabled={passwordData.disabled}
                      required={{
                        borderBottom: passwordData.errpassword ? true : false,
                      }}
                      style={{
                        borderBottom:
                          passwordData.errpassword && "3px solid red",
                      }}
                    />
                    <span
                      style={{
                        color: "rgb(243, 182, 51)",
                        margin: "0px 0px 30px 30px",
                      }}
                    >
                      {passwordData.errpassword}
                    </span>
                  </div>
                  <div className="">
                    <label htmlFor="passwordconfirm ">Password Confirm</label>
                    <input
                      className="form-control form-control-border border-width-2"
                      type="password"
                      id="passwordconfirm"
                      name="passwordconfirm"
                      onChange={handlePasswordChange}
                      value={formData.passwordconfirm}
                      disabled={passwordData.disabled}
                      required
                      style={{
                        borderBottom:
                          passwordData.errpasswordconfirm && "3px solid red",
                      }}
                    />
                    <span
                      style={{
                        color: "rgb(243, 182, 51)",
                        margin: "0px 0px 130px 30px",
                      }}
                    >
                      {passwordData.errpasswordconfirm}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <button
                    disabled={passwordData.disabled}
                    className="btn btn-primary pl-5 pr-5 btn-lg "
                  >
                    {passwordData.disabled && (
                      <span
                        className="spinner-border text-light spinner-border-sm "
                        role="status"
                        aria-hidden="true"
                      >
                        {/* <ImSpinner9 className="sr-only" /> */}
                      </span>
                    )}
                    <span>
                      {passwordData.status ? passwordData.statusMSG : " Submit"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
