import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { GraphQLClient, gql } from "graphql-request";
import * as env from "../env";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

function SelectPO() {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const payData = useSelector((state) => state.dashboard.poList);

  function handleSelect(params) {
    // console.log(params[1]["orgid"]);
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });
      const variables = {
        Status: "all_po_under_org",
        period_E: params[0]["orgname"],
        period_S: params[1]["orgid"],
      };
      const query = gql`
        query ($Status: String!, $period_S: String!, $period_E: String!) {
          List_PO(Status: $Status, period_S: $period_S, period_E: $period_E) {
            code_po
            code_inv
            amount
            tax
            balance
            rec_id
            com_name
            date_po
            date_invoice
          }
        }
      `;
      const data = await graphQLClient.request(query, variables);
      if (data["List_PO"].length > 0) {
        dispatch(reducer.getList({ poList: data["List_PO"] }));
        // MySwal.fire("Changed!", "", "success");
      } else {
        MySwal.fire("There are no records to display", "", "info");
        dispatch(reducer.getMyStae({ myState: "donit" }));

        handleOrgClose();
      }
    }
    main().catch((error) => {
      // console.error(error);
      let message = error.message.substring(0, 25);
      // console.log(message);
      if ("GraphQL Error (Code: 409)" === message) {
        return "Error try again.";
        // toast.error("Name is already in the system.");
      }
    });
  }

  function handleOrgClose(event) {
    dispatch(reducer.getList({ poList: [] }));
    dispatch(
      reducer.getEdith({
        edithPO: [],
      })
    );
    dispatch(reducer.getPayList({ payList: [] }));
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">List of possible organization</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleOrgClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Organization's Name</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {payData.map((fbb, index) => (
                  <tr key={index}>
                    <th scope="row">{Number(index) + 1}</th>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        data-dismiss="modal"
                        onClick={() =>
                          handleSelect([
                            { orgname: fbb.com_name },
                            { orgid: fbb.com_id },
                          ])
                        }
                        data-toggle="modal"
                        data-target="#modal-list_po"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        {" "}
                        {fbb.com_name}{" "}
                      </span>
                    </td>
                    <td>{fbb.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectPO;
