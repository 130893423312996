import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../Prints/PrintPOlist";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import { CSVLink } from "react-csv";
import { GraphQLClient, gql } from "graphql-request";
import DataTable from "react-data-table-component-footer";
import * as env from "../env";
import { MdArrowDownward } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Fill } from "react-icons/ri";

import { useSelector, useDispatch } from "react-redux";
import * as reducer from "../reducer";

import EdithPO from "../Modals/edithPO";
import EdithDate from "../Modals/edithDate";

import DatePicker from "react-datepicker";

const sortIcon = <MdArrowDownward />;
const customStyles = {
  rows: {
    style: {
      fontSize: "17px",
      minHeight: "40px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "500",
      textTransform: "uppercase",
      paddingLeft: "8px",
      color: "rgb(34, 5, 90)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  pagination: {
    style: {
      fontSize: "15px",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => row.status === "active",
    style: {
      // backgroundColor: "rgba(63, 195, 128, 0.9)",
      // color: "rgba(63, 195, 128, 0.9)",
      "&:hover": {
        // cursor: "pointer",
        backgroundColor: "rgba(156, 241, 87, 0.164)",
        // color: "rgb(34, 5, 90)",
        // borderRadius: "25px",
      },
    },
  },
];

const CustomLoader = () => (
  <div
    className="spinner-grow text-primary"
    role="status"
    style={{ padding: "24px" }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);
const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  placeholder,
  onChange,
}) => (
  <>
    <div className="input-group mb-3 ">
      <input
        id="search"
        type="text"
        placeholder={placeholder}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="form-control input-lg"
      />
      <span className="input-group-append">
        <button
          id="rz_submitBtn"
          type="button"
          onClick={onClear}
          className="btn btn-light"
          style={{ zIndex: 0 }}
        >
          Clear Text
        </button>
      </span>
    </div>
    <div className="input-group ">
      <div className="custom-control custom-radio pr-3">
        <input
          className="custom-control-input"
          type="radio"
          id="customRadio1"
          name="customRadio"
          onChange={onChange}
          checked={placeholder === "Filter By PO" && true}
        />
        <label htmlFor="customRadio1" className="custom-control-label">
          Filter By PO
        </label>
      </div>
      <div className="custom-control custom-radio">
        <input
          className="custom-control-input"
          type="radio"
          id="customRadio2"
          name="customRadio"
          onChange={onChange}
          checked={placeholder === "Filter By Organization's Name" && true}
        />
        <label htmlFor="customRadio2" className="custom-control-label">
          Filter By Organization's Name
        </label>
      </div>
    </div>
  </>
);

function Orglist() {
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  let { type } = useParams();

  const [startDate, setStartDate] = useState(new Date());

  const [filterBy, sethFilterBy] = useState({
    db: "code_po",
    name: "Filter By PO",
  });
  const [usersData, sethUsersData] = useState({
    title: "",
    allUsers: [],
    org: "",
    statusSend: "",
    currentYr: "",
    period_S: "",
    period_E: "",
  });
  usersData.allUsers = useSelector((state) => state.dashboard.poList);
  useEffect(() => {
    var statusSend = "active";
    var period_S = "";
    var period_E = "";

    if (type === ":all") {
      const formatYmd = () => startDate.toISOString().slice(0, 10);
      const getPeriod_D = formatYmd(new Date());
      const slcyr = getPeriod_D.split("-")[0];
      const getPeriod_S = slcyr + "-01-01";
      const getPeriod_E = slcyr + "-12-31";

      sethUsersData((prevUsersData) => ({
        ...prevUsersData,
        title: "all po's ",
        statusSend: "active",
        period_S: getPeriod_S,
        period_E: getPeriod_E,
        currentYr: slcyr,
      }));
      statusSend = "active";
      period_S = getPeriod_S;
      period_E = getPeriod_E;

      async function main() {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });

        const variables = {
          Status: statusSend,
          period_S: period_S,
          period_E: period_E,
        };
        const Query_Org = gql`
          query ($Status: String!, $period_S: String!, $period_E: String!) {
            List_PO(Status: $Status, period_S: $period_S, period_E: $period_E) {
              code_po
              code_inv
              amount
              tax
              balance
              rec_id
              com_name
              date_po
              date_invoice
            }
          }
        `;
        const AllUsers = await graphQLClient.request(Query_Org, variables);
        // sethUsersData((prevUsersData) => ({
        //   ...prevUsersData,
        //   allUsers: AllUsers["List_PO"],
        // }));
        dispatch(reducer.getList({ poList: AllUsers["List_PO"] }));
      }
      const timeout = setTimeout(() => {
        main().catch((error) => {
          console.error(error);
        });
        setPending(false);
      }, 2000);
      return () => clearTimeout(timeout);
    } else if (type === ":Unpaid_Invoice") {
      sethUsersData((prevUsersData) => ({
        ...prevUsersData,
        title: "all Unpaid PO's ",
        statusSend: "Unpaid_Invoice",
      }));
      statusSend = "Unpaid_Invoice";
      async function main() {
        const endpoint = env.SERVER_URL + "api/users";
        const graphQLClient = new GraphQLClient(endpoint, {
          credentials: "include",
          mode: "cors",
        });

        const variables = {
          Status: statusSend,
        };
        const Query_Org = gql`
          query ($Status: String!) {
            List_PO(Status: $Status) {
              code_po
              code_inv
              amount
              tax
              balance
              rec_id
              com_name
              date_po
              date_invoice
            }
          }
        `;
        const AllUsers = await graphQLClient.request(Query_Org, variables);
        dispatch(reducer.getList({ poList: AllUsers["List_PO"] }));
      }
      const timeout = setTimeout(() => {
        main().catch((error) => {
          console.error(error);
        });
        setPending(false);
      }, 2000);
      return () => clearTimeout(timeout);
    } else {
      // history.push("/dashboard");
      document.location = "/dashboard";
    }
  }, [type, startDate, dispatch]);
  const [pending, setPending] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // const [rows, setRows] = React.useState([]);
  const filteredItems = usersData.allUsers.filter(
    (item) =>
      item[filterBy.db] &&
      item[filterBy.db].toLowerCase().includes(filterText.toLowerCase())
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const EditComponent = ({ row }) => {
    const EditHandler = () => {
      // console.log(row);
      dispatch(reducer.getEdith({ edithPO: row }));
      dispatch(
        reducer.getMyStae({
          myState: [
            usersData.statusSend,
            usersData.period_S,
            usersData.period_E,
          ],
        })
      );
    };
    return (
      <BiEdit
        style={{ fontSize: "25px", cursor: "pointer" }}
        data-toggle="modal"
        data-target="#modal-edith_po"
        data-backdrop="static"
        data-keyboard="false"
        onClick={EditHandler}
      />
    );
  };
  const DeleteComponent = ({ row }) => {
    const DeleteHandler = () => {
      MySwal.fire({
        icon: "question",
        title: "Do you want to delete " + row.com_name + " ?",
        showCancelButton: true,
        confirmButtonText: "Yes delete",
      }).then((result) => {
        if (result.isConfirmed) {
          main(row);
          async function main(row) {
            if (row !== undefined) {
              const getOrgid = row.rec_id;
              const endpoint = env.SERVER_URL + "api/users";
              const graphQLClient = new GraphQLClient(endpoint, {
                credentials: "include",
                mode: "cors",
              });
              const variables = {
                Status: "deleted",
                orgid: getOrgid,
              };
              const query = gql`
                mutation ($orgid: String!, $Status: String!) {
                  Delete_PO(orgid: $orgid, Status: $Status) {
                    code_po
                    code_inv
                    amount
                    tax
                    balance
                    rec_id
                    com_name
                    date_po
                    date_invoice
                  }
                }
              `;
              const data = await graphQLClient.request(query, variables);
              if (data["Delete_PO"].length > 0) {
                setToggleCleared(!toggleCleared);
                setFilterText("");
                setPending(true);
                // sethUsersData((prevUsersData) => ({
                //   ...prevUsersData,
                //   org: "",
                //   allUsers: data["Delete_PO"],
                // }));
                dispatch(reducer.getList({ poList: data["Delete_PO"] }));
                setPending(false);
                MySwal.fire("Deleted!", "", "success");
              } else {
                MySwal.fire("Row is not deleted", "", "info");
              }
            }
          }
        }
      });
    };
    return (
      <RiDeleteBin5Fill
        style={{ fontSize: "25px", cursor: "pointer" }}
        onClick={DeleteHandler}
      />
    );
  };

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      // console.log(selectedRows);
      try {
        selectedRows.forEach(DeleteOrg);
      } catch (error) {
        console.log(error);
      }
      async function DeleteOrg(Org) {
        if (Org !== undefined) {
          const getOrgid = Org.com_id;

          const endpoint = env.SERVER_URL + "api/users";
          const graphQLClient = new GraphQLClient(endpoint, {
            credentials: "include",
            mode: "cors",
          });
          const variables = {
            Status: "deleted",
            orgid: getOrgid,
          };
          const query = gql`
            mutation ($orgid: String!, $Status: String!) {
              Delete_Org(orgid: $orgid, Status: $Status) {
                com_name
                status
                com_id
              }
            }
          `;
          const data = await graphQLClient.request(query, variables);
          if (data["Delete_Org"].length > 0) {
            MySwal.fire({
              icon: "success",
              title: "Successfully deleted",
              showConfirmButton: false,
              timer: 3000,
            });
            setToggleCleared(!toggleCleared);
            setFilterText("");
            setPending(true);
            sethUsersData((prevUsersData) => ({
              ...prevUsersData,
              org: "",
              allUsers: data["Delete_Org"],
            }));
            setPending(false);
          }
        }
      }
      DeleteOrg().catch((error) => {
        // toast.error(error);
        console.error(error);
      });

      // if (
      //   window.confirm(
      //     `Are you sure you want to delete:\r ${selectedRows.map(
      //       (r) => r.firstname + " " + r.lastname
      //     )}?`
      //   )
      // ) {
      //   setToggleCleared(!toggleCleared);
      //   // setData(differenceBy(data, selectedRows, "title"));
      // }
    };
    return (
      <button key="delete" onClick={handleDelete} className="btn btn-danger">
        Delete
      </button>
    );
  }, [MySwal, selectedRows, toggleCleared]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    function handlechecked(event) {
      if (event.target.id === "customRadio2") {
        sethFilterBy((prevFilterBy) => ({
          ...prevFilterBy,
          db: "com_name",
          name: "Filter By Organization's Name",
        }));
      } else {
        sethFilterBy((prevFilterBy) => ({
          ...prevFilterBy,
          db: "code_po",
          name: "Filter By PO",
        }));
      }
    }

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeholder={filterBy.name}
        onChange={handlechecked}
      />
    );
  }, [filterText, resetPaginationToggle, filterBy]);

  const columns = [
    {
      name: "PO",
      selector: (row) => row.code_po,
      sortable: true,
    },
    {
      name: "Invoice",
      selector: (row) => row.code_inv,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "3% Tax",
      selector: (row) => row.tax,
    },

    {
      name: "Balance",
      selector: (row) => row.balance,
    },

    {
      name: "Organization's Name",
      selector: (row) => row.com_name,
      sortable: true,
      grow: 2,
    },

    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    {
      cell: (row) => row.code_po.key !== null && <EditComponent row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "40px",
      style: {
        color: "blue",
      },
    },
    {
      cell: (row) => row.code_po.key !== null && <DeleteComponent row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "40px",
      style: {
        color: "red",
      },
    },
  ];

  const AmountSum = () => {
    let x = 0;
    // usersData.allUsers.map((item) => {
    filteredItems.map((item) => {
      x += Number(item.amount);
      return x;
    });
    return x;
  };
  const TaxSum = () => {
    let x = 0;
    // usersData.allUsers.map((item) => {
    filteredItems.map((item) => {
      x += Number(item.tax);
      return x;
    });
    return x;
  };

  const BalanceSum = () => {
    let x = 0;
    filteredItems.map((item) => {
      x += Number(item.balance);
      return x;
    });
    return x;
  };

  const footer = {
    // Role: yearSum(),

    code_po: "",
    amount: AmountSum().toLocaleString(),
    tax: TaxSum().toLocaleString(),
    balance: BalanceSum().toLocaleString(),
    // status: "",
    com_id: "",
  };
  const csvheaders = [
    { label: "PO Date", key: "date_po" },
    { label: "PO", key: "code_po" },
    { label: "Invoice Date", key: "date_invoice" },
    { label: "Invoice", key: "code_inv" },
    { label: "Amount", key: "amount" },
    { label: "Tax", key: "tax" },
    { label: "Balance", key: "balance" },
    { label: "Organization's Name", key: "com_name" },
  ];

  const formatYmd2 = () => startDate.toISOString().slice(0, 10);
  const getPeriod_D = formatYmd2(new Date());
  const slcyr = getPeriod_D.split("-")[0];
  if (slcyr !== usersData.currentYr) {
    const period_S = slcyr + "-01-01";
    const period_E = slcyr + "-12-31";
    const statusSend = "active";
    async function main() {
      const endpoint = env.SERVER_URL + "api/users";
      const graphQLClient = new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
      });

      const variables = {
        Status: statusSend,
        period_S: period_S,
        period_E: period_E,
      };
      const Query_Org = gql`
        query ($Status: String!, $period_S: String!, $period_E: String!) {
          List_PO(Status: $Status, period_S: $period_S, period_E: $period_E) {
            code_po
            code_inv
            amount
            tax
            balance
            rec_id
            com_name
            date_po
            date_invoice
          }
        }
      `;
      const AllUsers = await graphQLClient.request(Query_Org, variables);
      dispatch(reducer.getList({ poList: AllUsers["List_PO"] }));
      sethUsersData((prevUsersData) => ({
        ...prevUsersData,
        statusSend: "active",
        period_S: period_S,
        period_E: period_E,
        currentYr: slcyr,
      }));
    }
    main().catch((error) => {
      console.error(error);
    });
  }

  return (
    <div>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeonClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">PO Table</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">List Of PO</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="d-flex p-2 justify-content-between">
              <h3 className=" ">Table for {usersData.title}</h3>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="card-body">
              <DataTable
                title=" "
                customStyles={customStyles}
                pagination
                theme="solarized"
                columns={columns}
                data={filteredItems}
                // data={usersData.allUsers}
                conditionalRowStyles={conditionalRowStyles}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                contextActions={contextActions}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                // selectableRowsComponentProps={selectProps}

                // expandableRows
                // expandableRowsComponent={ExpandedComponent}
                highlightOnHover
                // pointerOnHover
                striped
                footer={footer}
                sortIcon={sortIcon}
                progressPending={pending}
                progressComponent={<CustomLoader />}
              />

              <CSVLink
                className="btn btn-success"
                data={filteredItems}
                headers={csvheaders}
                filename={"All PO List.csv"}
              >
                Download CSV
              </CSVLink>

              <ReactToPrint
                documentTitle={"Table for " + usersData.title}
                trigger={() => (
                  <button className="m-2 btn btn-primary">Print Table</button>
                )}
                content={() => componentRef.current}
              />
              <div className="d-none d-print-block" ref={componentRef}>
                <ComponentToPrint
                  data={filteredItems.length !== 0 && filteredItems}
                />
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </section>
      </div>

      {/* edith_po */}
      <div className="modal fade" id="modal-edith_po">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <EdithPO />
        </div>
      </div>
      <div className="modal fade " id="modal-add_date">
        <div className="modal-dialog modal-sm">
          <EdithDate />
        </div>
      </div>
    </div>
  );
}

export default Orglist;
